.c-journal-header {
	background-color: greyscale(95);
	padding-top: spacing(16);
}

.c-journal-header__title {
	font-size: 1.3125rem;
	margin: 0 0 spacing(16);

	> a {
		color: inherit;

		&:visited,
		&:hover,
		&:active {
			color: inherit;
		}
	}
}


.c-journal-header__grid {
	display: grid;
	width: 100%;
	grid-column-gap: 1.25rem;
	grid-template-columns: 1fr;
	/* stylelint-disable declaration-colon-space-after */
	grid-template-areas:
		"main" "side";
	/* stylelint-enable declaration-colon-space-after */

	@include media-query('md') {
		grid-column-gap: 1.25rem;
		grid-template-columns: 1fr 160px;
		/* stylelint-disable declaration-colon-space-after */
		grid-template-areas:
			"main    side";
		/* stylelint-enable declaration-colon-space-after */
	}

	@include media-query('lg') {
		grid-column-gap: 3.125rem;
		grid-template-columns: 1fr 190px;
		/* stylelint-disable declaration-colon-space-after */
		grid-template-areas:
			"main    side";
		/* stylelint-enable declaration-colon-space-after */
	}
}

.c-journal-header__grid-main,
.c-journal-header__grid-side {
	@include media-query('md') {
		width: auto !important;
		margin: 0 !important;
	}
}

.c-journal-header__grid-main {
	grid-area: main;
}

.c-journal-header__grid-side {
	position: relative;
	margin-left: 0; // override old browser styles
	grid-area: side;
}


.c-journal-header__cover {
	display: none;

	img {
		width: 100%;
		height: auto;
	}

	@include media-query('lg') {
		display: block;
		position: absolute;
		max-width: 90px;
		right: 10px;
		top: 5px;
	}

	@include media-query('xl') {
		right: auto;
		left: 0;
		max-width: 110px;
		top: 0;
	}
}
