.ctx-sidebar-nav {
	.c-nav__item {
		// Horrible nested padding to allow hover background of link
		/* stylelint-disable max-nesting-depth */
		> .c-nav {
			padding-left: 0;

			> .c-nav__item {
				> .c-nav__link {
					padding-left: 3em;
				}

				> .c-nav {
					> .c-nav__item {
						> .c-nav__link {
							padding-left: 4em;
						}
					}
				}
			}
		}
		/* stylelint-enable max-nesting-depth */
	}

	.c-nav__link {
		padding-left: 2em;
		padding-right: 2em;

		&:hover {
			color: color('action-base');
		}

		.u-flex__icon-left {
			margin-left: -1.5em;
		}

		.u-flex__icon-right {
			margin-right: -1.5em;
			margin-left: auto; // required for flexing off to end
		}
	}
}

.ctx-journal-header {
	.c-nav--tabs {
		border-bottom: 0;
	}
}
