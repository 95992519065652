.ctx-search {
	.c-form-field {
		margin-bottom: 0;
	}

	.c-form-field__input {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		margin-right: 0;
	}
}
