@charset "UTF-8";
/**
 * Enhanced
 * Bootstrap the rest of the styles on cut the mustard
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Breakpoints
 * Shared media query values
 */
/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Shared colors
 * Applicable to all brands and not to be overwritten
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 *  Button settings
 *  Default
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Style mixins
 *
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
/**
 * Colour palette
 *
 */
/**
 * Color and gray scales
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 *  Button settings
 *  Springer
 *
 */
/**
 * Generates utility classnames for base and each breakpoint
 *
 * Example:
 * @include class-stack('u-text-right') would output
 * u-text-right { @content }
 * @include media-query('xs') { u-text-right-at-lt-xs { @content } }
 *
 */
/**
 * Default link style
 *
 */
/**
 * Alternative link color
 *
 */
/**
 * Deemphasised link color
 *
 */
/**
 * Unvisited
 *
 */
/**
 * Link like
 * Style non-links to look like links
 * Remember to add aria
 *
 */
button {
  line-height: inherit;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 4px solid #ffcc00;
}

input[type=file]:focus-within {
  outline: 4px solid #ffcc00;
}

button:disabled:focus,
input:disabled:focus,
select:disabled:focus,
textarea:disabled:focus {
  outline: none;
}

label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

/*
 * Layout
 * Universal layout styles
 */
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  height: 100%;
  overflow-y: scroll;
  font-size: 100%;
  box-sizing: border-box;
  color: #333333;
  line-height: 1.6180339888;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  max-width: 100%;
  min-height: 100%;
  background: #fcfcfc;
  font-size: 1.125rem; /* fixes chrome rems bug - http://stackoverflow.com/questions/20099844/chrome-not-respecting-rem-font-size-on-body-tag */
}

figure {
  margin: 0;
}

body,
div,
dl,
dt,
dd,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
th,
td {
  margin: 0;
  padding: 0;
}

abbr[title] {
  text-decoration: none;
}

[contenteditable]:focus,
[tabindex="0"]:focus {
  outline: 4px solid #ffcc00;
}

a {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
a.visited, a:visited {
  color: #a345c9;
}
a.hover, a:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
a.active, a:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
a.focus, a:focus {
  outline: 4px solid #ffcc00;
}
a > img {
  vertical-align: middle;
}

table {
  border: 1px solid rgb(165.75, 165.75, 165.75);
  width: 100%;
  margin-bottom: 32px;
}

th,
td {
  font-size: 1rem;
  border: 1px solid rgb(165.75, 165.75, 165.75);
  padding: 0.3em 0.6em;
  vertical-align: top;
}

th {
  background: rgb(229.5, 229.5, 229.5);
  text-align: left;
}

h1 {
  font-style: normal;
  line-height: 1.4;
  font-size: 2.25rem;
  font-size: min(max(1.75rem, 4vw), 2.25rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
h1 a.active, h1 a:active, h1 a.hover, h1 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

h2 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3.5vw), 1.75rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
h2 a.active, h2 a:active, h2 a.hover, h2 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

h3 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3vw), 1.5rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
h3 a.active, h3 a:active, h3 a.hover, h3 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

h4 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.25rem;
  font-size: min(max(1.125rem, 2.5vw), 1.25rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
h4 a.active, h4 a:active, h4 a.hover, h4 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

h5,
h6 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.125rem;
  font-size: min(max(1rem, 2.5vw), 1.125rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
h5 a.active, h5 a:active, h5 a.hover, h5 a:hover,
h6 a.active,
h6 a:active,
h6 a.hover,
h6 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

/* Basic lists should be aligned to the left */
ul:not([class]),
ol:not([class]) {
  /* Allow for bullet itself */
  padding-left: 0.9em;
}

dd {
  margin: 0;
}

cite {
  font-style: normal;
}

/**
 * 10-Settings
 */
/**
 * @springernature/global-ad
 * Default skin settings
 *
 */
/**
 * @springernature/global-banner
 * Springer skin settings
 */
/**
 * @springernature/global-banner
 * Default skin settings
 *
 */
/**
 * @springernature/global-corporate-footer
 * Springer skin settings
 */
/**
 * @springernature/global-corporate-footer
 * Default skin settings
 *
 */
/**
 * List
 * Springer branding for component
 */
/**
 * Meta
 * Springer branding for component
 */
/**
 * Meta
 * Default branding for component
 */
/**
 * Popup
 * Default branding for component
 */
/**
 * @springernature/global-skip-link
 * Springer skin settings
 *
 */
/**
 * @springernature/global-skip-link
 * Default skin settings
 *
 */
/**
 * Status Message
 * Default branding for component
 */
/**
 * 30-Mixins
 */
/**
 * 50-Components
 */
.js .c-popup {
  position: absolute;
  font-family: Georgia, Palatino, serif;
  z-index: 100;
  padding: 16px;
  border: 1px solid #222222;
  box-shadow: 0 0 5px 0 rgba(127.5, 127.5, 127.5, 0.1);
  width: auto;
  border-radius: 2px;
  background-color: #fff;
}
.js .c-popup__close {
  position: absolute;
  display: block;
  top: 16px;
  right: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%0A%3Csvg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.29679575 12.2772478c-.39658757.3965876-.39438847 1.0328109-.00062148 1.4265779.39651227.3965123 1.03246768.3934888 1.42657791-.0006214l4.27724782-4.27724787 4.2772478 4.27724787c.3965876.3965875 1.0328109.3943884 1.4265779.0006214.3965123-.3965122.3934888-1.0324677-.0006214-1.4265779l-4.27724787-4.2772478 4.27724787-4.27724782c.3965875-.39658757.3943884-1.03281091.0006214-1.42657791-.3965122-.39651226-1.0324677-.39348875-1.4265779.00062148l-4.2772478 4.27724782-4.27724782-4.27724782c-.39658757-.39658757-1.03281091-.39438847-1.42657791-.00062148-.39651226.39651227-.39348875 1.03246768.00062148 1.42657791l4.27724782 4.27724782z' fill='%23666' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat;
  border: none;
  padding-right: 16px;
}
.js .c-popup__close-text {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.js .c-popup__arrow {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-top: 1px solid #222222;
  border-left: 1px solid #222222;
}
.js .c-popup__arrow--above {
  bottom: -11px;
  transform: rotate(225deg);
}
.js .c-popup__arrow--below {
  top: -11px;
  transform: rotate(45deg);
}

.c-forms__label,
.c-forms__hint,
.c-forms__error,
.c-forms__legend {
  display: block;
}

.c-forms__hint,
.c-forms__error {
  font-weight: normal;
}

.c-forms__input-container {
  position: relative;
}

.c-forms__fieldset,
.c-forms__error-summary,
.c-forms__field,
.c-forms__field * {
  line-height: 1.4;
}

.c-forms__field,
.c-forms__error-summary {
  font-size: 1rem;
}

.c-forms__field small {
  font-size: inherit;
}

.c-forms__fieldset,
.c-forms__legend {
  padding: 0;
  border: 0;
}

.c-forms__error-summary,
.c-forms__input-container,
.c-forms__field {
  max-width: 70ch;
}

.c-forms__inline-fields {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: var(--global-form-inline-gap, 0.5rem);
}

.c-forms__inline-fields--nowrap {
  flex-wrap: nowrap;
}

.c-forms__inline-fields .c-forms__field--globalFormText {
  /* ↓ Make it max out the available space with a super high value */
  flex-grow: 666;
}

.c-forms__inline-fields .c-forms__input {
  height: 100%;
}

.c-forms__pictographic-radios {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
}

@media only screen and (min-width: 320px) {
  .c-forms__pictographic-radios {
    flex-direction: row;
  }
  .c-forms__pictographic-radios .c-forms__label + .c-forms__label {
    margin-top: 0;
  }
}
* + .c-forms__error-summary,
* + .c-forms__fieldset,
* + .c-forms__field {
  margin-top: 1.5rem;
}

.c-forms__inline-fields * {
  margin: 0;
}

.c-forms__field .u-visually-hidden + * {
  margin-top: 0;
}

.c-forms__legend * {
  margin: 0;
}

.c-forms__input {
  box-sizing: border-box;
  border: 1px solid;
  color: #000000;
  width: 100%;
  padding: 0.5rem 0.5rem;
  background-color: #ffffff;
  font-weight: 400;
}

* + .c-forms__input,
* + .c-forms__input-container {
  margin-top: 0.25rem;
}

.c-forms__input:focus {
  outline: 3px solid #0088cc;
}

.c-forms__input[invalid] {
  border-color: #c40606;
  border-width: 2px;
}

.c-forms__input[disabled] {
  cursor: not-allowed;
  border-color: #dadada;
}

.c-forms__label {
  font-size: 1rem;
  font-weight: 700;
  color: #000000;
  padding: 0;
}

.c-forms__label + .c-forms__label {
  margin-top: 0.25rem;
}

.c-forms__hint {
  font-weight: 400;
  margin-top: 0.25rem;
  color: #666666;
}

.c-forms__error {
  color: #c40606;
  display: flex;
  align-items: flex-start;
}

label + .c-forms__error,
.c-forms__error + label {
  margin-top: 0.25rem;
}

.c-forms__error .c-forms__icon {
  color: #c40606;
}

.c-forms__icon svg {
  height: 1rem;
  width: 1rem;
  vertical-align: calc(0.5rem / 8 * -1);
  overflow: visible;
  pointer-events: none;
}

.c-forms__input--select {
  cursor: pointer;
  appearance: none;
  padding-right: 1.5rem;
}

.c-forms__input-container--select .c-forms__icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.c-forms__input--radio,
.c-forms__input--checkbox,
.c-form__label--visually-hidden {
  /* hide visually, not to assistive tech */
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.c-forms__label--inline {
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  padding: 0;
}

.c-forms__label--inline::before {
  content: "";
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #ffffff;
  border: 2px solid #999999;
  margin-right: 0.5rem;
}

.c-forms__label--pictographic-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-weight: 400;
  cursor: pointer;
}

@media only screen and (min-width: 320px) {
  .c-forms__label--pictographic-radio {
    flex-direction: column;
    justify-content: space-between;
  }
}
.c-forms__label--pictographic-radio svg {
  box-sizing: content-box;
  flex: 0 0 auto;
  width: var(--forms--pictographic-radio-icon-size, 4rem);
  height: var(--forms--pictographic-radio-icon-size, 4rem);
  fill: #ffffff;
  stroke: #01324b;
  background-color: #ffffff;
}

:checked + .c-forms__label--pictographic-radio svg {
  fill: #01324b;
  background-color: #01324b;
  stroke: #ffffff;
}

.c-forms__label--boxed-icon svg {
  padding: 0.5rem;
  border: 2px solid #999999;
}

:checked + .c-forms__label--boxed-icon svg {
  border-color: #ffffff;
}

:focus + .c-forms__label--pictographic-radio svg,
.c-forms__label--pictographic-radio:hover svg {
  box-shadow: 0 0 0 3px #0088cc;
}

:checked + .c-forms__label--inline::before {
  border-color: #01324b;
}

:focus + .c-forms__label--inline::before {
  box-shadow: 0 0 0 3px #0088cc;
}

.c-forms__label--radio::before {
  border-radius: 50%;
}

.c-forms__label--checkbox::before {
  border-radius: 0.125em;
}

[invalid] + .c-forms__label--checkbox::before {
  border-color: #c40606;
}

:checked + .c-forms__label--radio::before {
  background: radial-gradient(#01324b 0%, #01324b 40%, #fff 40%);
}

:checked + .c-forms__label--checkbox::before {
  background-color: #01324b;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="white" stroke-linejoin="round" fill="none" stroke-linecap="round" viewBox="0 0 20 20"><path d="M4.5,11 10,15 16,5"></path></svg>');
  border-color: #01324b;
  background-size: 100%;
  background-repeat: none;
  background-position: center;
}

.c-forms__sub-fields {
  margin-top: 0.25rem;
  margin-left: calc(1.5rem / 4);
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  position: relative;
  display: none;
}

.c-forms__sub-fields::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: calc(1.5rem / 2);
  background-color: #dadada;
  border-radius: 4px;
}

:checked ~ .c-forms__sub-fields {
  display: block;
}

.c-forms__error-summary {
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  color: #c40606;
  border: 1px solid #dadada;
  border-bottom: 4px solid #c40606;
}

.c-forms__error-summary .c-forms__icon svg {
  width: 2rem;
  height: 2rem;
}

.c-forms__error-summary-errors {
  padding: 0;
  margin-bottom: 0;
  margin-top: 0.5rem;
  list-style: none;
}

.c-forms__error-summary-errors a {
  color: inherit;
  text-decoration: underline;
}

.c-forms__error-summary-title {
  color: initial;
  font-weight: bold;
}

.c-forms__error-summary-errors li + li {
  margin-top: 0.25rem;
}

.c-customer-satisfaction-input {
  padding: 16px;
  background-color: #f3f3f3;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.c-customer-satisfaction-input__container {
  display: flex;
  justify-content: center;
}

.c-customer-satisfaction-input__heading {
  margin: 0 0 8px !important;
  font-family: inherit !important;
  font-size: 16px !important;
  line-height: 1.4 !important;
  font-weight: 700 !important;
}

.c-customer-satisfaction-input__submit-message div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-customer-satisfaction-input__submit-message svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.c-customer-satisfaction-input__submit-message p {
  margin: 0;
  line-height: 1.4;
  font-family: inherit;
  font-size: 16px;
}

.c-customer-satisfaction-input__button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 32px;
  border: 4px solid white;
  box-shadow: 0 0 0 1px #01324b;
  color: #01324b;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  margin: 8px 0 0 0;
  padding: 8px 16px;
  text-decoration: none;
  transition: all 0.2s;
  width: 100%;
}
.c-customer-satisfaction-input__button:hover {
  color: white;
  background-color: #01324b;
  border: 4px solid #01324b;
  box-shadow: none;
}
.c-customer-satisfaction-input__button:focus {
  border: 4px solid #ffcc00;
  box-shadow: none;
  outline: none;
}

.c-customer-satisfaction-input a {
  display: inline-block;
  margin-top: 8px;
  font-family: inherit;
  font-size: 16px;
}

.c-customer-satisfaction-input__visually-hidden,
.c-customer-satisfaction-input legend.c-forms__label {
  /* hide visually, not to assistive tech */
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Override Global Forms Styles */
.c-customer-satisfaction-input .c-forms__label--pictographic-radio svg {
  width: 21px;
  height: 21px;
  padding: 6px;
  border-radius: 2px;
}

.c-customer-satisfaction-input .c-forms__pictographic-radios {
  gap: 5px;
  justify-content: center;
}

.c-customer-satisfaction-input .c-forms__label {
  font-family: inherit;
  font-size: 12px;
}

@media only screen and (min-width: 540px) {
  .c-customer-satisfaction-input .c-forms__label--pictographic-radio svg {
    width: 24px;
    height: 24px;
    padding: 8px;
  }
  .c-customer-satisfaction-input .c-forms__pictographic-radios {
    gap: 16px;
  }
  .c-customer-satisfaction-input .c-forms__label {
    font-size: 14px;
  }
}
.c-customer-satisfaction-input .c-forms__fieldset--pictographic-radios {
  justify-content: center;
}

.c-customer-satisfaction-input .c-forms__label + .c-forms__label {
  margin-top: 0;
}

.c-customer-satisfaction-input .c-forms__fieldset {
  margin: 0;
}

.c-customer-satisfaction-input .c-forms__error {
  display: none;
  margin-bottom: 8px;
}

.c-customer-satisfaction-input--show-error .c-forms__error {
  display: flex;
}

.c-publisher-footer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  color: #fff;
  background-color: #01324b;
}

.c-publisher-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.c-publisher-footer__section {
  border-top: 2px solid #fff;
  padding-top: 24px;
  padding-bottom: 24px;
}

.c-publisher-footer__section--compact {
  padding-top: 16px;
  padding-bottom: 16px;
}

.c-publisher-footer__menu {
  column-gap: 24px;
}
@media only screen and (min-width: 320px) {
  .c-publisher-footer__menu {
    column-count: 2;
  }
}
@media only screen and (min-width: 768px) {
  .c-publisher-footer__menu {
    column-count: 4;
    column-gap: 32px;
  }
}

.c-publisher-footer__menu-group {
  break-inside: avoid-column;
  overflow: hidden;
  padding-left: 4px;
  margin-left: -4px;
  padding-bottom: 24px;
}
@media only screen and (min-width: 768px) {
  .c-publisher-footer__menu-group {
    padding-bottom: 0;
  }
}

.c-publisher-footer__heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.25em;
}

.c-publisher-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-publisher-footer__item {
  padding: 0.25em 0;
}

.c-publisher-footer__link,
.c-publisher-footer__link:visited {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  color: inherit;
  border: 0;
  padding: 0;
  background: none;
}

button.c-publisher-footer__link {
  text-decoration: underline;
}

.app-author-services-banner {
  z-index: 100001;
  color: #222;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Daytona W01 Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner {
    width: 670px;
    left: calc(50% - 335px);
    right: unset;
  }
}
.app-author-services-banner--is-visible {
  animation-name: slide-from-bottom;
  animation-duration: 0.6s;
  transform: translateY(0);
}

.app-author-services-banner__banner-large, .app-author-services-banner__banner-small {
  display: block;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.1);
}

.app-author-services-banner__banner-large {
  position: relative;
  display: none;
  max-width: 670px;
  border: 1px solid #b0b0b0;
  border-bottom: none;
}

.app-author-services-banner__banner-small {
  border-top: 1px solid #b0b0b0;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__banner-large {
    display: block;
  }
  .app-author-services-banner__banner-small {
    display: none;
  }
}
.app-author-services-banner__large-container, .app-author-services-banner__small-container, .app-author-services-banner__large-content-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.app-author-services-banner__large-container, .app-author-services-banner__small-container {
  width: 100%;
}

.app-author-services-banner__small-container {
  justify-content: space-between;
}

.app-author-services-banner__large-content-container {
  padding: 16px 16px 16px 0;
  flex-grow: 1;
}

.app-author-services-banner__small-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: nowrap;
  padding: 12px;
}

.app-author-services-banner__large-text-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-left: 24px;
  margin-right: 16px;
  flex-direction: column;
  flex-wrap: nowrap;
}

.app-author-services-banner__small-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
  margin-top: 12px;
}

.app-author-services-banner__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.273;
  max-width: 150px;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__text {
    font-size: 22px;
    max-width: 270px;
  }
}
.app-author-services-banner__small-image {
  display: block;
  max-width: 90px;
}

.app-author-services-banner__large-image {
  display: block;
}

.app-author-services-banner__image-cover-text {
  position: absolute;
  top: 137px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.273;
  padding: 4px 0;
  letter-spacing: -0.12px;
  text-align: center;
}

.app-author-services-banner__button {
  padding: 6px 8px;
  background-color: #EF2929;
  color: #fff;
  display: inline-block;
  max-width: 90px;
  text-align: center;
  line-height: 1.273;
  font-size: 11px;
}

a.app-author-services-banner__button:visited {
  color: #fff;
}

.app-author-services-banner__button span {
  font-weight: 400;
  letter-spacing: -0.13px;
  display: inline-block;
  transform: translateY(1px);
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__button {
    max-width: 124px;
    font-size: 16px;
  }
}
.app-author-services-banner__logo {
  max-width: 83px;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__logo {
    max-width: 162px;
  }
}
.app-author-services-banner__close-container {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 9px;
  top: 11px;
  border: 0;
  display: inline-block;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__close-container {
    right: 8px;
    top: 10px;
  }
}
.app-author-services-banner__close {
  margin-top: 0;
  background: 0 0;
  color: #222;
  vertical-align: top;
  border: 0;
  padding-right: 0;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__close {
    height: inherit;
  }
}
@keyframes slide-from-bottom {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 200px;
  }
}
video {
  display: block;
}

.c-video {
  border: 1px solid #cccccc;
  background-color: rgb(242.25, 242.25, 242.25);
}

.c-video__wrapper {
  position: relative;
}
.c-video__wrapper::before {
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 56.28272%;
}
.c-video__wrapper::after {
  content: "";
  display: table;
  clear: both;
}
.c-video--miVMPro .c-video__wrapper::before {
  padding-top: 60.4%;
}

.c-video__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.c-video__content iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.c-video__warning {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  padding: 48px;
  margin: 0;
  text-align: center;
}

.c-video__warning--hide {
  display: none;
}

.c-video__caption {
  padding: 16px;
  font-size: 0.875rem;
  background-color: white;
  border-top: 1px solid #cccccc;
}
.c-video__caption *:last-child {
  margin-bottom: 0;
}

.c-twitter-timeline__warning {
  margin-bottom: 0px;
}

.c-navbar {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  background: #e6e6e6;
  border-top: 1px solid rgb(216.75, 216.75, 216.75);
  border-bottom: 1px solid rgb(216.75, 216.75, 216.75);
  line-height: 1.6180339888;
  padding: 16px 0;
}

.c-navbar--with-submit-button {
  padding-bottom: 24px;
}
@media only screen and (min-width: 540px) {
  .c-navbar--with-submit-button {
    padding-bottom: 16px;
  }
}

.c-navbar__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-navbar__logo {
  background-color: transparent;
  display: inline-block;
  padding: 0;
}
.c-navbar__logo > a,
.c-navbar__logo > a > img {
  display: block;
}

.c-navbar__content {
  flex: 0 1 auto;
  display: flex;
}

.c-navbar__nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.c-navbar__item {
  flex: 0 0 auto;
}

.c-navbar__link {
  display: block;
  text-decoration: none;
  background: transparent;
  border: 0;
  text-transform: capitalize;
  color: currentColor;
}
.c-navbar__link:visited, .c-navbar__link:hover, .c-navbar__link:active {
  color: currentColor;
}
.c-navbar__link:hover {
  text-decoration: underline;
}
.c-navbar__link:active {
  outline: none;
}
.c-navbar__link--is-shown {
  text-decoration: underline;
}

.c-navbar__search-button {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .c-navbar__search-button {
    display: block;
  }
}

.c-ad {
  text-align: center;
}
@media only screen and (min-width: 320px) {
  .c-ad {
    padding: 8px;
  }
}

.c-ad--728x90,
.c-ad--970x90 {
  display: none;
  background-color: #ccc;
}
.c-ad--728x90 .c-ad__inner,
.c-ad--970x90 .c-ad__inner {
  min-height: calc(1.5em + 4px + 90px);
}
.c-ad--728x90 iframe,
.c-ad--970x90 iframe {
  max-width: 970px;
  height: 90px;
}

@media only screen and (min-width: 768px) {
  .js .c-ad--728x90 {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .js .u-show-following-ad + .c-ad--728x90 {
    display: block;
  }
}

.c-ad--160x600 {
  display: none;
  padding: 0;
  text-align: left;
}
.c-ad--160x600 iframe {
  width: 160px;
  height: 600px;
}

@media only screen and (min-width: 768px) {
  .js .c-ad--160x600 {
    display: block;
  }
}

.c-ad--300x250 {
  display: none;
  background-color: #f2f2f2;
  padding: 8px;
}
.c-ad--300x250 iframe {
  width: 300px;
  height: 250px;
}
.c-ad--300x250 .c-ad__inner {
  min-height: calc(1.5em + 4px + 250px);
}

@media only screen and (min-width: 320px) {
  .js .c-ad--300x250 {
    display: block;
  }
}

.c-ad iframe {
  border: none;
  overflow: auto;
  vertical-align: top;
}

.c-ad__label {
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  margin-bottom: 4px;
  color: #333;
  line-height: 1.5;
}

/**
 * @springernature/global-banner
 * A container with text and link
 *
 */
.c-banner {
  border-bottom: 1px solid rgb(0, 58.6666666667, 132);
  background-color: #f7fbfe;
  padding: 16px 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.c-banner--compact {
  font-size: 0.875em;
  padding: 4px 4px;
}

.c-banner--contrast {
  background-color: black;
  color: white;
}
.c-banner--contrast .c-banner__link {
  color: white;
}
.c-banner--contrast .c-banner__link:hover, .c-banner--contrast .c-banner__link:visited {
  color: white;
}

.c-banner--marketing {
  background-color: #004b83;
  border-bottom: 0;
  color: white;
}
.c-banner--marketing .c-banner__link {
  color: white;
}
.c-banner--marketing .c-banner__link:hover, .c-banner--marketing .c-banner__link:visited {
  color: white;
}

.c-banner--flush {
  padding-left: 0;
  padding-right: 0;
}

.c-banner__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-banner__container > :only-child {
  margin-left: auto;
  margin-right: auto;
}

.c-banner__container--center {
  justify-content: center;
}

.c-banner__item {
  flex: 0 1 auto;
  margin: 0;
}

.c-banner__link {
  text-decoration: underline;
  color: #004b83;
}
.c-banner__link:visited, .c-banner__link:hover {
  color: #004b83;
}

.c-corporate-footer {
  background-color: rgb(242.25, 242.25, 242.25);
  border-top: 1px solid #cccccc;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding-top: 16px;
  padding-bottom: 24px;
}

.c-corporate-footer__legal {
  color: #666666;
  font-size: 1rem;
  margin-bottom: 0px;
  padding-top: 4px;
}

.c-corporate-footer__link {
  color: inherit;
}
.c-corporate-footer__link.visited, .c-corporate-footer__link:visited {
  color: inherit;
}
.c-corporate-footer__link.hover, .c-corporate-footer__link:hover {
  color: inherit;
}

.c-list-group {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

.c-list-group__item > a.active, .c-list-group__item > a:active, .c-list-group__item > a.hover, .c-list-group__item > a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-list-group--xs .c-list-group__item {
  padding: 4px 0;
}

.c-list-group--sm .c-list-group__item {
  padding: 8px 0;
}

.c-list-group--md .c-list-group__item {
  padding: 16px 0;
}

.c-list-group--lg .c-list-group__item {
  padding: 24px 0;
}

.c-list-group--bordered .c-list-group__item:not(:last-child) {
  border-bottom: 1px solid #cccccc;
}

.c-list-group--flush .c-list-group__item:first-child {
  padding-top: 0;
}

.c-list-group--flush .c-list-group__item:last-child {
  padding-bottom: 0;
}

.c-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  color: #666666;
  line-height: inherit;
}

.c-meta--large {
  font-size: 1rem;
}
.c-meta--large .c-meta__item {
  margin-bottom: 8px;
}

.c-meta__item {
  display: inline-block;
  margin-bottom: 4px;
}
.c-meta__item:not(:last-child) {
  border-right: 1px solid rgb(216.75, 216.75, 216.75);
  padding-right: 8px;
  margin-right: 8px;
}

@media only screen and (max-width: 539px) {
  .c-meta__item--block-sm-max {
    display: block;
  }
  .c-meta__item--block-sm-max:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .c-meta__item--block-at-lg {
    display: block;
  }
  .c-meta__item--block-at-lg:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

.c-meta__type {
  text-transform: uppercase;
  font-weight: 700;
}

.c-pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.875rem;
  padding: 16px;
}
@media only screen and (min-width: 540px) {
  .c-pagination {
    justify-content: center;
  }
}

.c-pagination__item {
  margin-right: 16px;
  margin-bottom: 8px;
}
.c-pagination__item:last-child {
  margin-right: 0;
}

.c-pagination__link {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #004b83;
  background-color: rgb(242.25, 242.25, 242.25);
  background-image: linear-gradient(to bottom, white, rgb(242.25, 242.25, 242.25));
  border: 1px solid #cccccc;
  min-width: 30px;
  text-align: center;
}
.c-pagination__link svg {
  fill: currentColor;
}
.c-pagination__link:visited {
  color: #004b83;
}
.c-pagination__link:hover {
  border: 1px solid #666666;
  text-decoration: none;
}
.c-pagination__link:focus {
  border: 1px solid #666666;
  text-decoration: none;
}
.c-pagination__link:hover, .c-pagination__link:focus {
  color: white;
  background-color: #666666;
  background-image: none;
}
.c-pagination__link:hover svg path, .c-pagination__link:focus svg path {
  fill: white;
}

.c-pagination__link--disabled {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #004b83;
  background-color: rgb(242.25, 242.25, 242.25);
  background-image: linear-gradient(to bottom, white, rgb(242.25, 242.25, 242.25));
  border: 1px solid #cccccc;
  color: #333333;
  background-color: transparent;
  background-image: none;
  border: 1px solid #cccccc;
  opacity: 0.67;
  cursor: default;
}
.c-pagination__link--disabled svg {
  fill: currentColor;
}
.c-pagination__link--disabled:visited {
  color: #004b83;
}
.c-pagination__link--disabled:hover {
  border: 1px solid #666666;
  text-decoration: none;
}
.c-pagination__link--disabled:focus {
  border: 1px solid #666666;
  text-decoration: none;
}
.c-pagination__link--disabled:hover, .c-pagination__link--disabled:focus {
  color: white;
  background-color: #666666;
  background-image: none;
}
.c-pagination__link--disabled:hover svg path, .c-pagination__link--disabled:focus svg path {
  fill: white;
}
.c-pagination__link--disabled svg {
  fill: currentColor;
}
.c-pagination__link--disabled:visited {
  color: #333333;
}
.c-pagination__link--disabled:hover {
  border: 1px solid #cccccc;
  text-decoration: none;
}
.c-pagination__link--disabled:focus {
  border: 1px solid #cccccc;
  text-decoration: none;
}
.c-pagination__link--disabled:hover, .c-pagination__link--disabled:focus {
  color: #333333;
  background-color: transparent;
  background-image: none;
}
.c-pagination__link--disabled:hover svg path, .c-pagination__link--disabled:focus svg path {
  fill: #333333;
}

.c-pagination__link--active {
  color: white;
  border-color: #666666;
  background-color: #666666;
  background-image: none;
  cursor: default;
}

.c-pagination__ellipsis {
  background: none;
  border: 0;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}

.c-pagination__icon {
  fill: #999999;
  width: 16px;
  height: 12px;
}

.c-pagination__icon--active {
  fill: #004b83;
}

/**
 * @springernature/global-skip-link
 * A full width link which is hidden for mouse users
 *
 */
.c-skip-link {
  background: #f7fbfe;
  color: #004b83;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-align: center;
  padding: 8px;
  position: absolute;
  inset: 0;
  bottom: auto;
  z-index: 9999;
  transform: translateY(-100%);
}
@media (prefers-reduced-motion: reduce) {
  .c-skip-link {
    transition: top 0.3s ease-in-out;
  }
}
@media print {
  .c-skip-link {
    display: none;
  }
}
.c-skip-link:link, .c-skip-link:active, .c-skip-link:visited, .c-skip-link:hover {
  color: #004b83;
}
.c-skip-link:focus {
  transform: translateY(0%);
}

.c-status-message {
  display: flex;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.c-status-message *:last-child {
  margin-bottom: 0px;
}

.c-status-message--boxed {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #cccccc;
  line-height: 1.4;
  padding: 16px;
}

.c-status-message__heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.c-status-message__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 auto;
  margin-right: 8px;
}

.c-status-message__icon--top {
  align-self: flex-start;
}

.c-status-message--info .c-status-message__icon {
  color: #003f8d;
}

.c-status-message--boxed.c-status-message--info {
  border-bottom: 4px solid #003f8d;
}

.c-status-message--error .c-status-message__icon {
  color: #c40606;
}

.c-status-message--boxed.c-status-message--error {
  border-bottom: 4px solid #c40606;
}

.c-status-message--success .c-status-message__icon {
  color: #00b8b0;
}

.c-status-message--boxed.c-status-message--success {
  border-bottom: 4px solid #00b8b0;
}

.c-status-message--warning .c-status-message__icon {
  color: #edbc53;
}

.c-status-message--boxed.c-status-message--warning {
  border-bottom: 4px solid #edbc53;
}

.c-box {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #cccccc;
  line-height: 1.3;
  padding: 16px;
}

.c-box--background-grey {
  background-color: rgb(242.25, 242.25, 242.25);
}

.c-box--shadowed {
  box-shadow: 0 0 5px 0 rgba(127.5, 127.5, 127.5, 0.1);
}

.c-box--borderless {
  border: none;
}

.c-box__heading {
  font-size: 1.5rem;
  border-bottom: 1px solid #cccccc;
  margin: 0 0 16px;
  padding: 0 0 8px;
}

.c-box__item {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  display: block;
  margin-bottom: 16px;
}
.c-box__item.visited, .c-box__item:visited {
  color: #004b83;
}
.c-box__item.hover, .c-box__item:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.c-box__item.active, .c-box__item:active, .c-box__item.hover, .c-box__item:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-box__item-text {
  display: block;
  max-width: 90%;
}

.c-listing {
  list-style: none;
  padding: 0;
}

.c-listing__item {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.c-listing__content {
  min-width: 60%;
  flex-basis: 0;
  flex-grow: 666;
}

.c-listing__content > * + * {
  margin-top: 0.75rem;
}

.c-listing__item + .c-listing__item {
  margin-top: 16px;
  padding-top: 16px;
}

.c-listing__title {
  margin-bottom: 0;
}

.c-listing__authors {
  color: #666;
}

.c-listing__authors-list,
.c-listing__metadata {
  display: inline-flex;
  flex-wrap: wrap;
}

.c-listing__authors-list span + span:not(:first-child):not(:last-child)::after {
  content: ", ";
}

.c-listing__metadata span:not(:last-child)::after {
  content: "";
  border-left: 2px solid #cccccc;
  margin: 0 0.5em;
}

.c-listing__image {
  flex-basis: 200px;
  flex-grow: 1;
  position: relative;
  aspect-ratio: 9/6;
  align-self: flex-start;
}

.c-listing__image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #cccccc;
}

.c-listing__view-options {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
@supports (gap: 1em) {
  .c-listing__view-options {
    gap: 0.5em;
  }
}

@supports not (gap: 1em) {
  .c-listing__view-options li {
    margin: 0.25em;
  }
}

.c-listing__view-options a {
  display: flex;
  align-items: center;
}

.c-listing__view-options-icon {
  margin-right: 0.25em;
  fill: currentColor;
}

@supports (-webkit-line-clamp: 3) {
  .c-listing__intro--truncated {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: var(--listing-lines);
    -webkit-box-orient: vertical;
  }
}
.c-media {
  display: block;
}
@media only screen and (min-width: 540px) {
  .c-media {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 540px) {
  .c-media--stacked {
    display: block;
  }
}

.c-media__figure {
  position: relative;
}
@media only screen and (min-width: 540px) {
  .c-media__figure {
    max-width: 30%;
    flex: 1 0 30%;
  }
}
.c-media__figure a {
  display: block;
}

.c-media--stacked .c-media__figure {
  max-width: none;
}
@media only screen and (min-width: 540px) {
  .c-media--stacked .c-media__figure {
    margin-bottom: 16px;
  }
}

.c-media__body + .c-media__figure,
.c-media__figure + .c-media__body {
  margin-top: 16px;
}
@media only screen and (min-width: 540px) {
  .c-media__body + .c-media__figure,
  .c-media__figure + .c-media__body {
    margin-top: 0;
    margin-left: 16px;
  }
  .c-media--stacked .c-media__body + .c-media__figure,
  .c-media--stacked .c-media__figure + .c-media__body {
    margin-left: 0;
  }
}

.c-media__body,
.c-media__title {
  font-weight: normal;
}

.c-media__body {
  flex: 1 1 auto;
}
.c-media__body p {
  margin: 0;
}

.c-media__title {
  margin-bottom: 16px;
  font-size: 1.5rem;
}

.c-media__play {
  width: 40px;
  height: 40px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.c-media__play::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 14px;
  border-color: transparent transparent transparent #fff;
  margin-left: 3px;
}

.c-dropdown {
  position: relative;
  display: inline-block;
}

.c-dropdown__button {
  background-color: transparent;
  border: 0;
  padding: 0;
  display: inline-block;
  white-space: nowrap;
}
.c-dropdown__button::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 5px;
  border-color: currentColor transparent transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

.c-dropdown__menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  background-color: white;
  border-radius: 3px;
  border: 1px solid rgb(216.75, 216.75, 216.75);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  position: absolute;
  top: 100%;
  transform: translateY(8px);
  width: 180px;
  z-index: 100;
}
.c-dropdown__menu::before, .c-dropdown__menu::after {
  position: absolute;
  bottom: 100%;
}
.c-dropdown__menu::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent rgb(216.75, 216.75, 216.75) transparent;
  left: 16px;
  transform: translateX(-1px);
}
.c-dropdown__menu::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent white transparent;
  left: 16px;
}

.c-dropdown__menu--right {
  left: auto;
  right: 0;
}
.c-dropdown__menu--right::before, .c-dropdown__menu--right::after {
  left: auto;
  right: 16px;
}
.c-dropdown__menu--right::before {
  transform: translateX(1px);
}

.c-dropdown__link {
  display: block;
  padding: 4px 16px;
  background-color: transparent;
  color: #004b83;
}
.c-dropdown__link.active, .c-dropdown__link:active, .c-dropdown__link.hover, .c-dropdown__link:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.c-dropdown__link:visited {
  color: #004b83;
}
.c-dropdown__link:hover {
  background-color: rgb(0, 96.6984732824, 168.9);
  color: white;
}

.c-header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
}

.c-header {
  background-color: #fff;
  padding: 16px 0;
  border-bottom: 4px solid #00285a;
}

/* Fallback margins for IE11 */
.c-header__container > * + *,
.c-header__menu > * + *,
.c-header__nav-menu > * + * {
  margin-left: 2em;
}

@supports (gap: 2em) {
  .c-header__container > * + *,
  .c-header__menu > * + *,
  .c-header__nav-menu > * + * {
    margin-left: 0;
  }
}
.c-header__container,
.c-header__menu,
.c-header__nav-menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@supports (gap: 2em) {
  .c-header__container,
  .c-header__menu,
  .c-header__nav-menu {
    gap: 2em;
  }
}

.c-header__menu,
.c-header__nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-header__item {
  color: inherit;
}

.c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  justify-content: space-between;
}

.c-header__brand a {
  text-decoration: none;
  display: block;
}
.c-header__brand img {
  /* based on springer.com logo's intrinsic height */
  height: 30px;
  width: auto;
}

.c-header__link {
  color: inherit;
}
.c-header__link.active, .c-header__link:active, .c-header__link.hover, .c-header__link:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.c-header__link:visited {
  color: inherit;
}
.c-header__link:hover {
  color: inherit;
}

.c-header__link--nav {
  font-weight: bold;
}

.c-header__nav {
  border-top: 1px solid #cccccc;
  margin-top: 16px;
  padding-top: 16px;
}

.c-header__nav-menu {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.c-header__search-form {
  padding: 48px;
  display: none;
  justify-content: center;
  background-color: rgb(229.5, 229.5, 229.5);
}

.c-header__search-form:target,
.c-header__search-form--visible {
  display: flex;
}

.c-header__search-field {
  width: 40rem;
  max-width: 100%;
}

.c-header__search-field label {
  display: block;
  margin-bottom: 4px;
}

.c-header__search-controls {
  display: flex;
  margin-bottom: 8px;
}

.c-header__search-controls input,
.c-header__search-controls button {
  padding: 8px;
  border: 0;
  border-radius: 0;
}

.c-header__search-controls input {
  width: 100%;
  border: 1px solid #cccccc;
  border-width: 1px 0 0 1px;
}

.c-header__springer-link {
  font-size: 0.8em;
}

.c-anchored-heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 4px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 767px) {
  .c-anchored-heading--collapsible {
    position: relative;
    border-bottom: 0;
    margin: 0 -16px 8px;
    background-color: rgb(242.25, 242.25, 242.25);
    padding: 8px 16px;
    cursor: pointer;
  }
  .c-anchored-heading--collapsible::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 5px;
    border-color: #00285a transparent transparent transparent;
    position: absolute;
    right: 20px;
    top: 50%;
  }
  .c-anchored-heading--collapsible.is-shown {
    margin-bottom: 16px;
  }
  .c-anchored-heading--collapsible.is-shown::after {
    transform: rotate(180deg);
  }
}

.c-anchored-heading__title {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3.5vw), 1.75rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.4;
}
.c-anchored-heading__title a.active, .c-anchored-heading__title a:active, .c-anchored-heading__title a.hover, .c-anchored-heading__title a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
@media only screen and (max-width: 767px) {
  .c-anchored-heading--collapsible .c-anchored-heading__title {
    font-size: 1.5rem;
  }
}

.c-anchored-heading__helper {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  align-self: flex-end;
  line-height: 1.4;
  transform: translateY(-3px);
  white-space: nowrap;
}
.c-anchored-heading--collapsible .c-anchored-heading__helper {
  display: none;
}
@media only screen and (min-width: 768px) {
  .c-anchored-heading--collapsible .c-anchored-heading__helper {
    display: block;
  }
}

.c-ad--sticky {
  position: sticky;
  top: 20px;
}

.c-badge {
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.4em 0.6em;
  line-height: 1.2;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  border-radius: 3px;
}
.c-badge.visited, .c-badge:visited {
  color: #004b83;
}
.c-badge.hover, .c-badge:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.c-badge--success {
  background-color: #71ab0b;
  color: shade(#71ab0b, 70%);
  border: 1px solid shade(#71ab0b, 10%);
}
.c-badge--success:hover {
  background-color: shade(#71ab0b, 5%);
}
.c-badge--info {
  background-color: #ebf6ff;
  color: shade(#ebf6ff, 70%);
  border: 1px solid shade(#ebf6ff, 10%);
}
.c-badge--info:hover {
  background-color: shade(#ebf6ff, 5%);
}
.c-badge--warning {
  background-color: #ffcc00;
  color: shade(#ffcc00, 70%);
  border: 1px solid shade(#ffcc00, 10%);
}
.c-badge--warning:hover {
  background-color: shade(#ffcc00, 5%);
}
.c-badge--error {
  background-color: #c40606;
  color: shade(#c40606, 70%);
  border: 1px solid shade(#c40606, 10%);
}
.c-badge--error:hover {
  background-color: shade(#c40606, 5%);
}
.c-badge--neutral {
  background-color: rgb(242.25, 242.25, 242.25);
  color: shade(rgb(242.25, 242.25, 242.25), 70%);
  border: 1px solid shade(rgb(242.25, 242.25, 242.25), 10%);
}
.c-badge--neutral:hover {
  background-color: shade(rgb(242.25, 242.25, 242.25), 5%);
}

.js .c-collapse {
  display: none;
}
.js .c-collapse.is-shown {
  display: block;
}
@media only screen and (min-width: 320px) {
  .js .c-collapse-at-xs {
    display: none;
  }
  .js .c-collapse-at-xs.is-shown {
    display: block;
  }
}
@media only screen and (min-width: 540px) {
  .js .c-collapse-at-sm {
    display: none;
  }
  .js .c-collapse-at-sm.is-shown {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .js .c-collapse-at-md {
    display: none;
  }
  .js .c-collapse-at-md.is-shown {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .js .c-collapse-at-lg {
    display: none;
  }
  .js .c-collapse-at-lg.is-shown {
    display: block;
  }
}
@media only screen and (min-width: 1220px) {
  .js .c-collapse-at-xl {
    display: none;
  }
  .js .c-collapse-at-xl.is-shown {
    display: block;
  }
}
@media only screen and (max-width: 319px) {
  .js .c-collapse-at-lt-xs {
    display: none;
  }
  .js .c-collapse-at-lt-xs.is-shown {
    display: block;
  }
}
@media only screen and (max-width: 539px) {
  .js .c-collapse-at-lt-sm {
    display: none;
  }
  .js .c-collapse-at-lt-sm.is-shown {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .js .c-collapse-at-lt-md {
    display: none;
  }
  .js .c-collapse-at-lt-md.is-shown {
    display: block;
  }
}
@media only screen and (max-width: 1023px) {
  .js .c-collapse-at-lt-lg {
    display: none;
  }
  .js .c-collapse-at-lt-lg.is-shown {
    display: block;
  }
}
@media only screen and (max-width: 1219px) {
  .js .c-collapse-at-lt-xl {
    display: none;
  }
  .js .c-collapse-at-lt-xl.is-shown {
    display: block;
  }
}

.c-divider {
  border-bottom: 1px solid rgb(216.75, 216.75, 216.75);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.c-form-field {
  margin-bottom: 1em;
}

.c-form-field__label {
  font-size: 0.875rem;
  display: block;
  color: #666666;
  margin-bottom: 0.4em;
}

.c-form-field__input,
.c-form-field__select {
  font-size: 0.875rem;
  border: 1px solid rgb(178.5, 178.5, 178.5);
  border-radius: 3px;
  vertical-align: middle;
  line-height: 1.2857142857;
}

.c-form-field__input {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.21);
  padding: 0.75em 1em;
  width: 100%;
}
.c-form-field__input--auto {
  width: auto;
}

.c-form-field__select {
  height: 40px;
}
.c-form-field__select--auto {
  height: auto;
}

.c-form-field__error {
  font-size: 1rem;
  color: #c40606;
  display: block;
}

.c-form-field--inline {
  display: flex;
}
.c-form-field--inline .c-form-field__label,
.c-form-field--inline .c-form-field__input,
.c-form-field--inline .c-form-field__select {
  margin-bottom: 0;
  margin-right: 0.75em;
}
.c-form-field--inline .c-form-field__label:last-child,
.c-form-field--inline .c-form-field__input:last-child,
.c-form-field--inline .c-form-field__select:last-child {
  margin-right: 0;
}
.c-form-field--inline .c-form-field__input {
  flex-grow: 1;
  width: auto;
}

.c-form-field--option input[type=checkbox],
.c-form-field--option input[type=radio] {
  margin-right: 0.5em;
  transform: translateY(1px);
}
.c-form-field--option .c-form-field__label {
  display: flex;
  align-items: baseline;
}

.c-form-group {
  margin-bottom: 1em;
}
.c-form-group .c-form-field {
  margin-bottom: 0;
}

.c-impact-factor {
  color: rgb(127.5, 127.5, 127.5);
  font-weight: 700;
  margin: 0;
  line-height: 1.2;
  font-size: 1rem;
  text-align: right;
}

.c-impact-factor__value {
  color: #00285a;
}

.c-image-copyright {
  position: relative;
}

.c-image-copyright__text {
  font-size: 1.1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0 4px;
  background-color: #fff;
  color: #666666;
}

.c-lazy-image {
  filter: blur(30px);
  width: 100%;
  height: auto;
}
.c-lazy-image--loaded {
  filter: none;
  width: auto;
}

.c-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  display: flex;
  flex-wrap: wrap;
  line-height: 1.4285714286;
  word-break: normal;
}

.c-nav__item > .c-nav {
  padding-left: 1em;
}

.c-nav__link {
  color: inherit;
  display: block;
  padding: 0.4em 1em;
}
.c-nav__link.active, .c-nav__link:active, .c-nav__link.hover, .c-nav__link:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.c-nav__link.visited, .c-nav__link:visited {
  color: inherit;
}
.c-nav__link.hover, .c-nav__link:hover {
  color: inherit;
}
.c-nav__link.is-active {
  font-weight: 700;
}

.c-nav--stacked {
  flex-direction: column;
}
.c-nav--stacked .c-nav__item {
  width: 100%;
}

.c-nav--fill .c-nav__item {
  flex-grow: 1;
}

.c-nav--tabs {
  flex-wrap: nowrap;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.c-nav--tabs .c-nav__item:not(:first-child) {
  margin-left: -1px;
}
.c-nav--tabs .c-nav__link {
  height: 100%;
  background-color: rgb(229.5, 229.5, 229.5);
  border: 1px solid rgb(229.5, 229.5, 229.5);
  border-bottom: 0;
  padding: 0.7em 1.8em;
  position: relative;
  transition: border-left-color 0.2s ease, border-top-color 0.2s ease, border-right-color 0.2s ease, background-color 0.2s ease;
}
.c-nav--tabs .c-nav__link.is-active {
  background-color: #fcfcfc;
  z-index: 1;
  border-color: #ee7d11;
  border-bottom-color: transparent;
  position: relative;
}
.c-nav--tabs .c-nav__link.is-active::after {
  content: "";
  background-color: #fcfcfc;
  display: block;
  position: absolute;
  height: 1px;
  top: 100%;
  right: 0;
  left: 0;
}

/* grid-column-gap and grid-template-areas are duplicated in places. Do not remove the duplicates, this must be done for autoprefixer to correctly apply IE vendor prefixes */
.c-page-layout {
  display: grid;
}
.c-page-layout--sticky-footer {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-areas: "header" "body" "footer";
}
.c-page-layout--landing {
  grid-column-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-areas: "main" "side";
}
@media only screen and (min-width: 768px) {
  .c-page-layout--landing {
    grid-column-gap: 30px;
    grid-template-columns: 1fr 160px;
    grid-template-areas: "main    side";
  }
}
@media only screen and (min-width: 1024px) {
  .c-page-layout--landing {
    grid-column-gap: 50px;
    grid-template-columns: 1fr 190px;
    grid-template-areas: "main    side";
  }
}
.c-page-layout--default {
  grid-column-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-areas: "nav" "main" "side";
}
@media only screen and (min-width: 768px) {
  .c-page-layout--default {
    grid-column-gap: 30px;
    grid-template-columns: 160px 1fr;
    grid-template-areas: "nav     main" " .      side";
  }
}
@media only screen and (min-width: 1024px) {
  .c-page-layout--default {
    grid-column-gap: 50px;
    grid-template-columns: 190px 1fr;
    grid-template-areas: "nav     main" " .      side";
  }
}
@media only screen and (min-width: 1220px) {
  .c-page-layout--default {
    grid-column-gap: 50px;
    grid-template-columns: 190px 1fr 190px;
    grid-template-areas: "nav     main    side";
  }
}
.c-page-layout--fulltext {
  grid-column-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-areas: "nav" "main" "side";
}
@media only screen and (min-width: 768px) {
  .c-page-layout--fulltext {
    grid-column-gap: 30px;
    grid-template-columns: 1fr 160px;
    grid-template-areas: "main    side";
  }
}
@media only screen and (min-width: 1024px) {
  .c-page-layout--fulltext {
    grid-column-gap: 50px;
    grid-template-columns: 1fr 190px;
    grid-template-areas: "main    side";
  }
}
@media only screen and (min-width: 1220px) {
  .c-page-layout--fulltext {
    grid-column-gap: 50px;
    grid-template-columns: 190px 1fr 190px;
    grid-template-areas: "nav     main    side";
  }
}

.c-page-layout__nav {
  grid-area: nav;
}

.c-page-layout__main {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  grid-area: main;
  min-width: 0;
}

.c-page-layout__side {
  grid-area: side;
}
@media only screen and (max-width: 1023px) {
  .c-page-layout__side {
    margin-top: 24px;
  }
}

.c-page-layout__header {
  grid-area: header;
}

.c-page-layout__body {
  grid-area: body;
}

.c-page-layout__footer {
  grid-area: footer;
}

.c-popup-search {
  position: relative;
  z-index: 10;
  background-color: rgb(242.25, 242.25, 242.25);
  padding: 16px 0;
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.21);
}
@media only screen and (min-width: 1024px) {
  .js .c-popup-search {
    position: absolute;
    width: 100%;
    top: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .c-popup-search__container {
    margin: auto;
    max-width: 70%;
  }
}

.c-publisher-footer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  background: transparent;
  padding: 16px;
  border-top: 1px solid #cccccc;
}

.js .c-tab-content {
  display: none;
}
.js .c-tab-content.is-active {
  display: block;
}

.ctx-search .c-form-field {
  margin-bottom: 0;
}
.ctx-search .c-form-field__input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}

.c-journal-header {
  background-color: rgb(242.25, 242.25, 242.25);
  padding-top: 16px;
}

.c-journal-header__title {
  font-size: 1.3125rem;
  margin: 0 0 16px;
}
.c-journal-header__title > a {
  color: inherit;
}
.c-journal-header__title > a:visited, .c-journal-header__title > a:hover, .c-journal-header__title > a:active {
  color: inherit;
}

.c-journal-header__grid {
  display: grid;
  width: 100%;
  grid-column-gap: 1.25rem;
  grid-template-columns: 1fr;
  /* stylelint-disable declaration-colon-space-after */
  grid-template-areas: "main" "side";
  /* stylelint-enable declaration-colon-space-after */
}
@media only screen and (min-width: 768px) {
  .c-journal-header__grid {
    grid-column-gap: 1.25rem;
    grid-template-columns: 1fr 160px;
    /* stylelint-disable declaration-colon-space-after */
    grid-template-areas: "main    side";
    /* stylelint-enable declaration-colon-space-after */
  }
}
@media only screen and (min-width: 1024px) {
  .c-journal-header__grid {
    grid-column-gap: 3.125rem;
    grid-template-columns: 1fr 190px;
    /* stylelint-disable declaration-colon-space-after */
    grid-template-areas: "main    side";
    /* stylelint-enable declaration-colon-space-after */
  }
}

@media only screen and (min-width: 768px) {
  .c-journal-header__grid-main,
  .c-journal-header__grid-side {
    width: auto !important;
    margin: 0 !important;
  }
}

.c-journal-header__grid-main {
  grid-area: main;
}

.c-journal-header__grid-side {
  position: relative;
  margin-left: 0;
  grid-area: side;
}

.c-journal-header__cover {
  display: none;
}
.c-journal-header__cover img {
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 1024px) {
  .c-journal-header__cover {
    display: block;
    position: absolute;
    max-width: 90px;
    right: 10px;
    top: 5px;
  }
}
@media only screen and (min-width: 1220px) {
  .c-journal-header__cover {
    right: auto;
    left: 0;
    max-width: 110px;
    top: 0;
  }
}

.c-panel .c-list-group__item:first-child {
  padding-top: 0;
}
.c-panel .c-list-group__item:last-child {
  padding-bottom: 0;
}

.c-media blockquote {
  border: 0;
  padding: 0;
  font-size: inherit;
  margin-bottom: 0;
}

.c-media__title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.ctx-sidebar-nav .c-nav__item {
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
}
.ctx-sidebar-nav .c-nav__item > .c-nav {
  padding-left: 0;
}
.ctx-sidebar-nav .c-nav__item > .c-nav > .c-nav__item > .c-nav__link {
  padding-left: 3em;
}
.ctx-sidebar-nav .c-nav__item > .c-nav > .c-nav__item > .c-nav > .c-nav__item > .c-nav__link {
  padding-left: 4em;
}
.ctx-sidebar-nav .c-nav__link {
  padding-left: 2em;
  padding-right: 2em;
}
.ctx-sidebar-nav .c-nav__link:hover {
  color: #004b83;
}
.ctx-sidebar-nav .c-nav__link .u-flex__icon-left {
  margin-left: -1.5em;
}
.ctx-sidebar-nav .c-nav__link .u-flex__icon-right {
  margin-right: -1.5em;
  margin-left: auto;
}

.ctx-journal-header .c-nav--tabs {
  border-bottom: 0;
}

@media only screen and (min-width: 1024px) {
  body.journal .c-page-layout__side {
    margin-top: 30px;
  }
}

.c-navbar {
  font-size: 0.875rem;
}

/**
 * Legacy
 */
.c-associated-societies__title {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 16px;
}

.c-associated-societies__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-associated-societies__list-item + .c-associated-societies__list-item {
  margin-top: 8px;
}
.c-associated-societies__list-item p {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin: 0;
}

.c-collapse-heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: inherit;
  font-weight: 400;
  font-style: normal;
  margin: 0;
}

.c-collapse-heading__icon {
  display: inline-block;
  fill: #004b83;
}
[data-toggle=collapse].is-shown > .c-collapse-heading__icon {
  transform: rotate(180deg);
}

.c-crossmark-button {
  padding: 0;
  background: transparent;
  border: 0;
}
.c-crossmark-button > img {
  display: block;
  width: 150px;
}

.c-enhanced-select {
  position: relative;
}

.c-enhanced-select__filter {
  background-color: #fff;
  border: 1px solid #cccccc;
  display: none;
  left: 0;
  margin-top: -1px;
  padding: 16px;
  position: absolute;
  width: 100%;
  z-index: 11;
}
.c-enhanced-select__filter.is-shown {
  display: block;
}

.c-enhanced-select__chosen {
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #cccccc;
  cursor: pointer;
  display: flex;
  padding: 0.75em 1em;
  align-items: center;
  justify-content: space-between;
}
.c-enhanced-select__chosen svg {
  fill: #666666;
  transition: transform 250ms ease;
}
.c-enhanced-select__chosen.is-shown svg {
  transform: rotate(180deg);
}
.c-enhanced-select__chosen.is-hidden {
  display: none;
}

.c-enhanced-select__list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.c-enhanced-select__item {
  padding: 4px 8px;
}
.c-enhanced-select__item.is-highlighted {
  outline: none;
  background-color: rgb(242.25, 242.25, 242.25);
}

.c-enhanced-select_search-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 24px;
  top: 30px;
}

.c-export-citations {
  border: 1px solid #cccccc;
}
.js .c-export-citations {
  display: none;
}
.js .c-export-citations.is-shown {
  display: block;
}

.c-export-citations__section {
  padding-bottom: 8px;
}
.c-export-citations__section + .c-export-citations__section {
  border-top: 1px solid #cccccc;
}

.c-export-citations__title {
  font-size: 1rem;
  color: inherit;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 4px;
  padding: 8px 8px 0;
}

.c-export-citations__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.c-export-citations__list a {
  font-size: 1rem;
  display: block;
  padding: 4px 8px;
  text-decoration: none;
}
.c-export-citations__list a:hover {
  background-color: #004b83;
  color: #fff;
}
.c-export-citations__list a:hover .c-export-citations__icon {
  fill: #fff;
}

.c-export-citations__icon {
  fill: #004b83;
}

.c-journal-category-logo {
  border-bottom: 1px solid rgb(216.75, 216.75, 216.75);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.c-journal-category-logo + .c-associated-societies {
  margin-top: -17px;
}

.c-journal-footer {
  font-size: 1.25rem;
  border-top: 1px solid #cccccc;
  padding-top: 16px;
  padding-bottom: 16px;
}

.c-journal-footer__inner {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
@media only screen and (min-width: 768px) {
  .c-journal-footer__inner {
    display: flex;
    justify-content: space-between;
  }
}

.c-journal-footer__summary {
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .c-journal-footer__summary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.c-journal-footer__title {
  font-size: 1.5rem;
  margin-bottom: 8px;
  display: flex;
  font-style: normal;
}

.c-journal-footer__logo {
  display: block;
  transform: scale(0.75);
  transform-origin: left;
}

.c-journal-footer__title-text {
  display: block;
  flex: 0 1 auto;
  align-self: center;
}

.c-journal-footer__issn {
  color: #00285a;
  margin-bottom: 0;
}

.c-journal-footer__contact-title {
  font-size: 1.25rem;
  font-style: normal;
}
@media only screen and (min-width: 768px) {
  .c-journal-footer__contact-title {
    margin-bottom: 16px;
  }
}
.c-journal-footer__contact-title--adjust-for-logo {
  margin-top: 18px;
}

.c-journal-footer__contact-list {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #00285a;
}

.c-jump-navigation {
  overflow-x: scroll;
  white-space: nowrap;
}
@media only screen and (min-width: 768px) {
  .c-jump-navigation {
    overflow-x: visible;
    white-space: normal;
  }
}

.c-jump-navigation__title {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3vw), 1.5rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.c-jump-navigation__title a.active, .c-jump-navigation__title a:active, .c-jump-navigation__title a.hover, .c-jump-navigation__title a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-jump-navigation__item {
  display: inline;
  margin: 0;
  font-size: 0;
}
@media only screen and (min-width: 768px) {
  .c-jump-navigation__item {
    font-size: 1.25rem;
  }
}

.c-jump-navigation__link {
  font-size: 1.25rem;
  background-color: rgb(253.3, 242, 231.2);
  border-top: 1px solid #ee7d11;
  border-bottom: 1px solid #ee7d11;
  display: inline-block;
  padding: 4px;
  text-align: center;
  text-decoration: none;
  min-width: 2.5em;
  color: #00285a;
}
@media only screen and (min-width: 768px) {
  .c-jump-navigation__link {
    border-left: 1px solid #ee7d11;
    border-right: 1px solid #ee7d11;
  }
}
.c-jump-navigation__link:hover {
  background-color: rgb(250.41, 219.9, 190.74);
  color: #00285a;
}
.c-jump-navigation__link:visited {
  color: #00285a;
}
.c-jump-navigation__link--disabled {
  color: #666666;
}
.c-jump-navigation__link--disabled:hover {
  background-color: rgb(253.3, 242, 231.2);
  color: #666666;
  cursor: pointer;
}
.c-jump-navigation .c-jump-navigation__item:first-of-type .c-jump-navigation__link {
  border-left: 1px solid #ee7d11;
}
.c-jump-navigation .c-jump-navigation__item:last-of-type .c-jump-navigation__link {
  border-right: 1px solid #ee7d11;
}

.c-listing-title {
  font-weight: 400;
}

.c-listing-title__context {
  font-size: 1.5rem;
  color: #666666;
  display: block;
}

.c-logo {
  display: block;
}

.c-search-institution {
  position: relative;
}

.c-search-institution__header {
  display: none;
}
.js .c-search-institution__header {
  display: block;
}

.c-search-institution__content {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.c-search-institution__message {
  margin-bottom: 0;
}

.c-search-institution__results {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 300px;
  width: 100%;
}

.js .c-search-institution__item:focus {
  background-color: rgb(229.5, 229.5, 229.5);
  cursor: default;
  outline: none;
}

.c-search-institution__content {
  background-color: #fff;
  border: 1px solid #cccccc;
  border-top: 0;
  padding: 16px;
}

.c-separator {
  border-bottom: 1px solid rgb(216.75, 216.75, 216.75);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.c-separator--top {
  border-top: 1px solid rgb(216.75, 216.75, 216.75);
  padding-top: 16px;
  margin-top: 16px;
}
.c-separator--heavy {
  border-color: rgb(0, 58.6666666667, 132);
  border-bottom-width: 2px;
}
.c-separator--heavy.c-separator--top {
  border-top-width: 2px;
}

.c-separator-inline {
  border-right: 1px solid rgb(216.75, 216.75, 216.75);
  padding-right: 16px;
  margin-right: 16px;
  display: inline;
}

.c-sidebar-nav {
  font-size: inherit;
  position: relative;
}
.c-sidebar-nav.is-fixed {
  position: fixed;
  top: 0;
  z-index: 1;
}

.c-sidebar-nav__title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: inherit;
  font-weight: 400;
  margin-bottom: 0;
  display: none;
}
.js .c-sidebar-nav__title {
  display: block;
}
@media only screen and (min-width: 768px) {
  .js .c-sidebar-nav__title {
    display: none;
  }
}

.c-sidebar-nav__toggle {
  text-decoration: underline;
}
.c-sidebar-nav__toggle::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 5px;
  border-color: #00285a transparent transparent transparent;
  margin-left: 8px;
}
.c-sidebar-nav__toggle.is-shown::after {
  transform: rotate(180deg);
}

.c-sidebar-nav__menu {
  list-style: none;
  margin: 8px 0 0;
  padding: 0;
  position: absolute;
  z-index: 5;
  top: 100%;
  background-color: #fff;
  border: 1px solid #cccccc;
  font-weight: 400;
  line-height: 1.4;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.js .c-sidebar-nav__menu {
  display: none;
}
.js .c-sidebar-nav__menu.is-shown {
  display: block;
}
@media only screen and (min-width: 1220px) {
  .c-sidebar-nav__menu {
    display: block !important;
    position: static;
    margin-top: 0;
    border: none;
    background: transparent;
    box-shadow: none;
  }
}

.c-sidebar-nav__sub-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.c-sidebar-nav__menu-item--is-shown > .c-sidebar-nav__sub-menu {
  background-color: rgb(250.41, 219.9, 190.74);
}
.c-sidebar-nav__sub-menu .c-sidebar-nav__menu-link {
  padding-left: 24px;
}
.c-sidebar-nav__sub-menu .c-sidebar-nav__menu-link:hover {
  background-color: rgb(246.5, 190, 136);
}
.c-sidebar-nav__sub-menu .c-sidebar-nav__sub-menu .c-sidebar-nav__menu-link {
  padding-left: 32px;
}

.c-sidebar-nav__menu-link {
  display: block;
  padding: 6px 20px;
}
.c-sidebar-nav__menu-link--ext-link::after {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  width: 10px;
  height: 10px;
  background: url("../../images/ext-link.svg") no-repeat center;
}
.c-sidebar-nav__menu-link:hover {
  background-color: rgb(250.41, 219.9, 190.74);
}
.c-sidebar-nav__menu-item--has-children > .c-sidebar-nav__menu-link {
  align-items: baseline;
  display: flex;
  padding-left: 4px;
}
.c-sidebar-nav__sub-menu .c-sidebar-nav__menu-item--has-children > .c-sidebar-nav__menu-link {
  padding-left: 20px;
}
.c-sidebar-nav__menu-item--is-shown > .c-sidebar-nav__menu-link, .c-sidebar-nav__sub-menu-item--is-shown > .c-sidebar-nav__menu-link {
  background-color: rgb(250.41, 219.9, 190.74);
  font-weight: bold;
}
.c-sidebar-nav__sub-menu-item--is-shown > .c-sidebar-nav__menu-link {
  background-color: rgb(246.5, 190, 136);
}

.c-sidebar-nav__submenu-icon {
  fill: #00285a;
  flex: 0 0 auto;
  margin-right: 4px;
}

.c-social-links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: -8px;
}
.c-social-links::before, .c-social-links::after {
  content: "";
  display: table;
}
.c-social-links::after {
  clear: both;
}

.c-social-links__item {
  float: left;
  line-height: 0;
  margin-top: 8px;
  margin-right: 8px;
}
.c-social-links__item:last-child {
  margin-right: 0;
}
.c-social-links__item button {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.c-social-links__icon {
  fill: #00285a;
}

.c-steps {
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: steps-counter;
}

.c-steps__item {
  margin-bottom: 24px;
}
.c-steps__item::before {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3.5vw), 1.75rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
  font-size: 2.25rem;
  content: counters(steps-counter, "") ".";
  counter-increment: steps-counter;
  display: inline-block;
  margin-right: 4px;
  color: #ee7d11;
}
.c-steps__item::before a.active, .c-steps__item::before a:active, .c-steps__item::before a.hover, .c-steps__item::before a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-steps__title {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3.5vw), 1.75rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 16px;
}
.c-steps__title a.active, .c-steps__title a:active, .c-steps__title a.hover, .c-steps__title a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-steps__intro {
  font-weight: 700;
}

.c-steps__list {
  /* stylelint-disable scss/no-global-function-names */
  border-top: 1px solid #999999;
  /* stylelint-enable scss/no-global-function-names */
  border-bottom: 1px solid #cccccc;
}

.c-tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
}
@media only screen and (min-width: 768px) {
  .c-tabs {
    border-bottom: 1px solid #cccccc;
    justify-content: flex-start;
  }
}
.c-tabs--tight-to-content {
  margin-bottom: 8px;
}

.c-tabs__item {
  font-size: 1rem;
  line-height: normal;
  flex: 0 0 49%;
  max-width: 49%;
  margin-top: 2%;
}
.c-tabs__item:nth-child(-n+2) {
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .c-tabs__item {
    flex: none;
    max-width: none;
    background: #fff;
    margin-top: 0;
  }
  .c-tabs--stretch .c-tabs__item {
    flex: 1;
  }
}

.c-tabs__link {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  display: block;
  padding: 0.5em 0.8em;
  position: relative;
  /* stylelint-disable scss/no-global-function-names */
  background: linear-gradient(rgb(242.25, 242.25, 242.25), rgb(219.3, 219.3, 219.3));
  /* stylelint-enable scss/no-global-function-names */
  border: 1px solid #cccccc;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .c-tabs__link {
    padding: 0.8em 2em;
    background: #fff;
    border-color: transparent;
    border-bottom: 0;
    transition: border-top-color 0.25s ease;
  }
  .c-tabs--stretch .c-tabs__link {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.c-tabs__link::before {
  content: "";
  background-color: #ee7d11;
  display: block;
  position: absolute;
  height: 2px;
  bottom: 100%;
  right: -1px;
  left: -1px;
  opacity: 0;
  transform: scaleX(0);
  transition: transform 0.25s ease, opacity 0.25s ease;
}
.c-tabs__item.is-shown .c-tabs__link {
  color: #00285a;
  background: #fff;
}
.c-tabs__item.is-shown .c-tabs__link:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .c-tabs__item.is-shown .c-tabs__link {
    position: relative;
    border-color: #cccccc;
  }
  .c-tabs__item.is-shown .c-tabs__link::after {
    content: "";
    background-color: #fff;
    display: block;
    position: absolute;
    height: 1px;
    top: 100%;
    right: 0;
    left: 0;
  }
}
.c-tabs__item.is-shown .c-tabs__link::before {
  transform: scaleX(1);
  opacity: 1;
}

.js .c-tab-content {
  display: none;
}
.js .c-tab-content.is-shown {
  display: block;
}
.no-js .c-tab-content {
  display: block;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.tooltip-tether {
  position: absolute;
  margin-top: 19px;
  margin-left: -1px;
  padding: 16px;
  padding-right: 14px;
  border: 1px solid #cccccc;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  z-index: get-z-index(front-content);
  background-color: #fff;
  width: 50%;
  max-width: 300px;
}
@media only screen and (min-width: 768px) {
  .tooltip-tether {
    width: auto;
  }
}
.tooltip-tether--opr {
  width: 250px;
}
.js-CollapseSection .tooltip-tether {
  display: none;
}
@media only screen and (min-width: 768px) {
  .js-CollapseSection .tooltip-tether {
    display: block;
  }
}
.js-CollapseSection.is-shown .tooltip-tether {
  display: block;
}
.tooltip-tether.tether-element-attached-bottom {
  margin-top: -19px;
}
.tooltip-tether__arrow {
  background-image: url("/static/images/bmc/fulltext/tooltip-arrow.svg");
  background-size: contain;
  width: 36px;
  height: 18px;
  z-index: get-z-index(front-content);
  position: absolute;
  bottom: 100%;
}
.tether-element-attached-right .tooltip-tether__arrow {
  right: 20px;
}
.tether-element-attached-bottom .tooltip-tether__arrow, .tether-element-pinned-left .tooltip-tether__arrow {
  top: 100%;
  transform: rotate(180deg);
}
.tooltip-tether__heading {
  margin-bottom: 12px;
  line-height: normal;
}
.tooltip-tether__sub-heading {
  font-size: 1rem;
}
.tooltip-tether__body {
  font-size: 1rem;
  color: #666666;
}
.tooltip-tether__body > p {
  margin-bottom: 8px;
}
.tooltip-tether__body > p:last-child {
  margin-bottom: 0;
}
.tooltip-tether__indexed-item {
  list-style-type: decimal;
  display: list-item;
  margin: 0.5em 1em;
  padding: 0 0.2em;
  white-space: normal;
}
.tooltip-tether a, .tooltip-tether__link {
  display: block;
  margin-top: 16px;
}
.tooltip-tether__link-icon {
  display: inline-block;
  margin: 0 8px 0 0;
  top: 1px;
  position: relative;
}
.tooltip-tether__close-button {
  font-size: 1rem;
  background-color: transparent;
  border: 0;
  padding: 8px;
  position: absolute;
  top: -8px;
  right: -3px;
  display: block;
}
.tooltip-tether__close-button:after {
  content: "";
  width: 9px;
  height: 9px;
  background: transparent url("/static/images/bmc/fulltext/close.svg");
  background-size: cover;
  display: block;
}
.tooltip-tether .ContactIcon,
.tooltip-tether .OrcidIcon {
  margin-right: 4px;
}

/**
 * 60-Utilities
 */
/**
 * Button utilities
 * These are for styled branded buttons
 */
.u-button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #004b83;
  background-color: rgb(242.25, 242.25, 242.25);
  background-image: linear-gradient(to bottom, white, rgb(242.25, 242.25, 242.25));
  border: 1px solid #cccccc;
}
.u-button svg {
  fill: currentColor;
}
.u-button:visited {
  color: #004b83;
}
.u-button:hover {
  border: 1px solid #666666;
  text-decoration: none;
}
.u-button:focus {
  border: 1px solid #666666;
  text-decoration: none;
}
.u-button:hover, .u-button:focus {
  color: white;
  background-color: #666666;
  background-image: none;
}
.u-button:hover svg path, .u-button:focus svg path {
  fill: white;
}

.u-button--primary {
  color: white;
  background-color: rgb(51, 97.9333333333, 156.6);
  background-image: linear-gradient(to bottom, rgb(76.5, 117.5666666667, 168.9), rgb(51, 97.9333333333, 156.6));
  border: 1px solid rgba(0, 58.6666666667, 132, 0.5);
}
.u-button--primary svg {
  fill: currentColor;
}
.u-button--primary:visited {
  color: white;
}
.u-button--primary:hover {
  border: 1px solid rgb(0, 96.6984732824, 168.9);
}
.u-button--primary:focus {
  border: 1px solid rgb(0, 96.6984732824, 168.9);
}
.u-button--primary:hover, .u-button--primary:focus {
  color: white;
  background-color: rgb(0, 96.6984732824, 168.9);
  background-image: none;
}
.u-button--primary:hover svg path, .u-button--primary:focus svg path {
  fill: white;
}

.u-button--tertiary {
  color: white;
  background-color: #33629d;
  background-image: linear-gradient(to bottom, #4d76a9, #33629d);
  border: 1px solid rgba(0, 59, 132, 0.5);
  font-weight: normal;
}
.u-button--tertiary svg {
  fill: currentColor;
}
.u-button--tertiary:visited {
  color: white;
}
.u-button--tertiary:hover {
  border: 1px solid #0061a9;
}
.u-button--tertiary:focus {
  border: 1px solid #0061a9;
}
.u-button--tertiary:hover, .u-button--tertiary:focus {
  color: white;
  background-color: #0061a9;
  background-image: none;
}
.u-button--tertiary:hover svg path, .u-button--tertiary:focus svg path {
  fill: white;
}

.u-button--contrast {
  color: white;
  background-color: transparent;
  background-image: none;
  border: 1px solid white;
  font-weight: normal;
}
.u-button--contrast svg {
  fill: currentColor;
}
.u-button--contrast:visited {
  color: white;
}
.u-button--contrast:hover {
  border: 1px solid white;
}
.u-button--contrast:focus {
  border: 1px solid white;
}
.u-button--contrast:hover, .u-button--contrast:focus {
  color: black;
  background-color: white;
  background-image: none;
}
.u-button--contrast:hover svg path, .u-button--contrast:focus svg path {
  fill: black;
}

.u-button--ghost {
  color: #333333;
  background-color: transparent;
  background-image: none;
}
.u-button--ghost svg {
  fill: currentColor;
}
.u-button--ghost:visited {
  color: #333333;
}
.u-button--ghost:hover, .u-button--ghost:focus {
  color: #333333;
  background-color: rgba(242.25, 242.25, 242.25, 0.5);
  background-image: none;
}
.u-button--ghost:hover svg path, .u-button--ghost:focus svg path {
  fill: #333333;
}

.u-button--disabled,
.u-button:disabled {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border: 1px solid #cccccc;
  opacity: 0.67;
  cursor: default;
}
.u-button--disabled svg,
.u-button:disabled svg {
  fill: currentColor;
}
.u-button--disabled:visited,
.u-button:disabled:visited {
  color: #333333;
}
.u-button--disabled:hover,
.u-button:disabled:hover {
  border: 1px solid #cccccc;
  text-decoration: none;
}
.u-button--disabled:focus,
.u-button:disabled:focus {
  border: 1px solid #cccccc;
  text-decoration: none;
}
.u-button--disabled:hover, .u-button--disabled:focus,
.u-button:disabled:hover,
.u-button:disabled:focus {
  color: #333333;
  background-color: transparent;
  background-image: none;
}
.u-button--disabled:hover svg path, .u-button--disabled:focus svg path,
.u-button:disabled:hover svg path,
.u-button:disabled:focus svg path {
  fill: #333333;
}

.u-button--xsmall {
  font-size: 0.875rem;
  padding: 2px;
}

.u-button--small {
  font-size: 0.875rem;
  padding: inherit;
}

.u-button--large {
  font-size: 1rem;
  padding: 16px;
}

.u-button--full-width {
  display: flex;
  width: 100%;
}

.u-button--icon-left svg {
  margin-right: 8px;
}

.u-button--icon-right svg {
  margin-left: 8px;
}

.u-clearfix::before, .u-clearfix::after {
  content: "";
  display: table;
}
.u-clearfix::after {
  clear: both;
}

.u-color-open-access {
  color: #b74616;
}

.u-color-inherit {
  color: inherit;
}

.u-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.u-display-block {
  display: block;
}

.u-display-inline {
  display: inline;
}

.u-display-inline-block {
  display: inline-block;
}

.u-display-flex {
  display: flex;
  width: 100%;
}

.u-display-inline-flex {
  display: inline-flex;
}

.u-display-grid {
  display: grid;
}

.u-display-table {
  display: table;
}

.u-flex-direction-row {
  flex-direction: row;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-baseline {
  align-items: baseline;
}

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-left {
  justify-content: left;
}

.u-flex-grow {
  flex: 1 0 auto;
}

.u-flex-grow-shrink {
  flex: 1 1 auto;
}

.u-flex-shrink {
  flex: 0 1 auto;
}

.u-flex-static {
  flex: 0 0 auto;
}

.u-align-self-center {
  align-self: center;
}

.u-align-self-start {
  align-self: flex-start;
}

.u-align-self-end {
  align-self: flex-end;
}

.u-display-none {
  display: none;
}

/* hide from both screenreaders and browsers */
.u-hide,
.js .u-js-hide {
  display: none;
  visibility: hidden;
}
.u-hide:first-child + *,
.js .u-js-hide:first-child + * {
  margin-block-start: 0;
}

/* show to both screenreaders and browsers */
.u-show,
.js .u-js-show {
  display: block;
  visibility: visible;
}

.u-show-inline,
.js .u-js-show-inline {
  display: inline;
  visibility: visible;
}

/* hide only visually, but have it available for screenreaders */
.u-visually-hidden,
.js .u-js-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.u-visually-hidden--off,
.js .u-js-visually-hidden--off {
  border: 0;
  clip: initial;
  height: auto;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
  width: auto;
}

/* make invisible but retain dimensions */
.u-invisible {
  visibility: hidden;
}

/* hide only the text, keep element visible */
.u-hide-text,
.js .u-js-hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  vertical-align: bottom;
}

/* hiding overflow */
.u-hide-overflow {
  overflow: hidden;
}

@media print {
  .u-hide-print {
    display: none;
  }
}
/**
 * media-query hiding
 * intentionally avoids 'max' and 'range' to prevent bloat
 */
@media only screen and (min-width: 320px) {
  .u-hide-at-xs,
  .js .u-js-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xs:first-child + *,
  .js .u-js-hide-at-xs:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xs,
  .js .u-js-show-at-xs {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 540px) {
  .u-hide-at-sm,
  .js .u-js-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-sm:first-child + *,
  .js .u-js-hide-at-sm:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-sm,
  .js .u-js-show-at-sm {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 768px) {
  .u-hide-at-md,
  .js .u-js-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-md:first-child + *,
  .js .u-js-hide-at-md:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-md,
  .js .u-js-show-at-md {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1024px) {
  .u-hide-at-lg,
  .js .u-js-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lg:first-child + *,
  .js .u-js-hide-at-lg:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-lg,
  .js .u-js-show-at-lg {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1220px) {
  .u-hide-at-xl,
  .js .u-js-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xl:first-child + *,
  .js .u-js-hide-at-xl:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xl,
  .js .u-js-show-at-xl {
    display: block;
    visibility: visible;
  }
}
.u-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
}

.u-link-inherit {
  color: inherit;
}
.u-link-inherit.visited, .u-link-inherit:visited {
  color: inherit;
}
.u-link-inherit.hover, .u-link-inherit:hover {
  color: inherit;
}

.u-link-underline {
  text-decoration: underline;
}

.u-link-faux-block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-list-style-number {
  list-style-type: decimal;
}

.u-list-style-bullet {
  list-style-type: disc;
}

.u-list-style-dash > ::marker {
  content: "– ";
  font-size: 1.2em;
  line-height: 1;
}

.u-list-style-none {
  list-style-type: none;
}

.u-list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.u-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-inline > li {
  display: inline;
}

.u-custom-list-number {
  float: left;
  width: 3em;
  padding-right: 0.5em;
  margin-left: -3.5em;
  text-align: right;
}

.u-list-comma-separated {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-comma-separated > li {
  display: inline;
}
.u-list-comma-separated > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.u-list-comma-separated > li:not(:only-child):last-child::before {
  content: " & ";
}

.u-list-truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.u-position-top {
  top: 0;
}

.u-position-left {
  left: 0;
}

.u-position-bottom {
  bottom: 0;
}

.u-position-right {
  right: 0;
}

.u-position-negative-right {
  right: 100%;
}

.u-ma-0 {
  margin: 0;
}

.u-ma-2 {
  margin: 2px;
}

.u-ma-4 {
  margin: 4px;
}

.u-ma-8 {
  margin: 8px;
}

.u-ma-16 {
  margin: 16px;
}

.u-ma-24 {
  margin: 24px;
}

.u-ma-32 {
  margin: 32px;
}

.u-ma-48 {
  margin: 48px;
}

.u-ma-64 {
  margin: 64px;
}

.u-ma-auto {
  margin: auto;
}

.u-mt-0 {
  margin-top: 0;
}

.u-mt-2 {
  margin-top: 2px;
}

.u-mt-4 {
  margin-top: 4px;
}

.u-mt-8 {
  margin-top: 8px;
}

.u-mt-16 {
  margin-top: 16px;
}

.u-mt-24 {
  margin-top: 24px;
}

.u-mt-32 {
  margin-top: 32px;
}

.u-mt-48 {
  margin-top: 48px;
}

.u-mt-64 {
  margin-top: 64px;
}

.u-mt-auto {
  margin-top: auto;
}

.u-mr-0 {
  margin-right: 0;
}

.u-mr-2 {
  margin-right: 2px;
}

.u-mr-4 {
  margin-right: 4px;
}

.u-mr-8 {
  margin-right: 8px;
}

.u-mr-16 {
  margin-right: 16px;
}

.u-mr-24 {
  margin-right: 24px;
}

.u-mr-32 {
  margin-right: 32px;
}

.u-mr-48 {
  margin-right: 48px;
}

.u-mr-64 {
  margin-right: 64px;
}

.u-mr-auto {
  margin-right: auto;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-2 {
  margin-bottom: 2px;
}

.u-mb-4 {
  margin-bottom: 4px;
}

.u-mb-8 {
  margin-bottom: 8px;
}

.u-mb-16 {
  margin-bottom: 16px;
}

.u-mb-24 {
  margin-bottom: 24px;
}

.u-mb-32 {
  margin-bottom: 32px;
}

.u-mb-48 {
  margin-bottom: 48px;
}

.u-mb-64 {
  margin-bottom: 64px;
}

.u-mb-auto {
  margin-bottom: auto;
}

.u-ml-0 {
  margin-left: 0;
}

.u-ml-2 {
  margin-left: 2px;
}

.u-ml-4 {
  margin-left: 4px;
}

.u-ml-8 {
  margin-left: 8px;
}

.u-ml-16 {
  margin-left: 16px;
}

.u-ml-24 {
  margin-left: 24px;
}

.u-ml-32 {
  margin-left: 32px;
}

.u-ml-48 {
  margin-left: 48px;
}

.u-ml-64 {
  margin-left: 64px;
}

.u-ml-auto {
  margin-left: auto;
}

.u-pa-0 {
  padding: 0;
}

.u-pa-2 {
  padding: 2px;
}

.u-pa-4 {
  padding: 4px;
}

.u-pa-8 {
  padding: 8px;
}

.u-pa-16 {
  padding: 16px;
}

.u-pa-24 {
  padding: 24px;
}

.u-pa-32 {
  padding: 32px;
}

.u-pa-48 {
  padding: 48px;
}

.u-pa-64 {
  padding: 64px;
}

.u-pt-0 {
  padding-top: 0;
}

.u-pt-2 {
  padding-top: 2px;
}

.u-pt-4 {
  padding-top: 4px;
}

.u-pt-8 {
  padding-top: 8px;
}

.u-pt-16 {
  padding-top: 16px;
}

.u-pt-24 {
  padding-top: 24px;
}

.u-pt-32 {
  padding-top: 32px;
}

.u-pt-48 {
  padding-top: 48px;
}

.u-pt-64 {
  padding-top: 64px;
}

.u-pr-0 {
  padding-right: 0;
}

.u-pr-2 {
  padding-right: 2px;
}

.u-pr-4 {
  padding-right: 4px;
}

.u-pr-8 {
  padding-right: 8px;
}

.u-pr-16 {
  padding-right: 16px;
}

.u-pr-24 {
  padding-right: 24px;
}

.u-pr-32 {
  padding-right: 32px;
}

.u-pr-48 {
  padding-right: 48px;
}

.u-pr-64 {
  padding-right: 64px;
}

.u-pb-0 {
  padding-bottom: 0;
}

.u-pb-2 {
  padding-bottom: 2px;
}

.u-pb-4 {
  padding-bottom: 4px;
}

.u-pb-8 {
  padding-bottom: 8px;
}

.u-pb-16 {
  padding-bottom: 16px;
}

.u-pb-24 {
  padding-bottom: 24px;
}

.u-pb-32 {
  padding-bottom: 32px;
}

.u-pb-48 {
  padding-bottom: 48px;
}

.u-pb-64 {
  padding-bottom: 64px;
}

.u-pl-0 {
  padding-left: 0;
}

.u-pl-2 {
  padding-left: 2px;
}

.u-pl-4 {
  padding-left: 4px;
}

.u-pl-8 {
  padding-left: 8px;
}

.u-pl-16 {
  padding-left: 16px;
}

.u-pl-24 {
  padding-left: 24px;
}

.u-pl-32 {
  padding-left: 32px;
}

.u-pl-48 {
  padding-left: 48px;
}

.u-pl-64 {
  padding-left: 64px;
}

.u-keyline {
  border-bottom: 1px solid rgb(216.75, 216.75, 216.75);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-keyline--thick {
  border-bottom: 2px solid rgb(216.75, 216.75, 216.75);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-shadow {
  border: 1px solid black;
  box-shadow: 5px 5px 5px black;
}

.u-button-reset {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.u-monospace {
  font-family: monospace;
}

.u-sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.u-serif {
  font-family: Georgia, Palatino, serif;
}

.u-small-caps {
  font-size: 0.8em; /* scale based on containing text */
  text-transform: uppercase;
}

.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-normal {
  font-weight: 400;
}

.u-text-bold {
  font-weight: 700;
}

.u-double-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
  border-bottom: 1px solid;
}

.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.u-upper {
  text-transform: uppercase;
}

.u-lower {
  text-transform: lowercase;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
}

.u-unstyled {
  font-weight: normal;
  font-style: normal;
}

.u-h1 {
  font-style: normal;
  line-height: 1.4;
  font-size: 2.25rem;
  font-size: min(max(1.75rem, 4vw), 2.25rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h1 a.active, .u-h1 a:active, .u-h1 a.hover, .u-h1 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h2 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3.5vw), 1.75rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h2 a.active, .u-h2 a:active, .u-h2 a.hover, .u-h2 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h3 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3vw), 1.5rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h3 a.active, .u-h3 a:active, .u-h3 a.hover, .u-h3 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h4 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.25rem;
  font-size: min(max(1.125rem, 2.5vw), 1.25rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
.u-h4 a.active, .u-h4 a:active, .u-h4 a.hover, .u-h4 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-text-xs {
  font-size: 0.875rem;
}

.u-text-sm {
  font-size: 1rem;
}

.u-text-md {
  font-size: 1.25rem;
}

.u-text-lg {
  font-size: 1.5rem;
}

.u-text-sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.u-text-serif {
  font-family: Georgia, Palatino, serif;
}

.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-text-right {
  text-align: right;
}

.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

/**
 * Classes used to maintain the semantically appropriate heading levels
 */
.u-h1 {
  font-style: normal;
  line-height: 1.4;
  font-size: 2.25rem;
  font-size: min(max(1.75rem, 4vw), 2.25rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h1 a.active, .u-h1 a:active, .u-h1 a.hover, .u-h1 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h2 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3.5vw), 1.75rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h2 a.active, .u-h2 a:active, .u-h2 a.hover, .u-h2 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h3 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3vw), 1.5rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h3 a.active, .u-h3 a:active, .u-h3 a.hover, .u-h3 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h4 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.25rem;
  font-size: min(max(1.125rem, 2.5vw), 1.25rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
.u-h4 a.active, .u-h4 a:active, .u-h4 a.hover, .u-h4 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h5 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.125rem;
  font-size: min(max(1rem, 2.5vw), 1.125rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
.u-h5 a.active, .u-h5 a:active, .u-h5 a.hover, .u-h5 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-vh-full {
  min-height: 100vh;
}

.u-color-success {
  color: #71ab0b;
}

.u-color-info {
  color: #004b83;
}

.u-hide {
  display: none;
  visibility: hidden;
}
.u-hide:first-child + * {
  margin-block-start: 0;
}

@media only screen and (min-width: 320px) {
  .u-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xs:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (min-width: 540px) {
  .u-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-sm:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (min-width: 768px) {
  .u-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-md:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .u-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lg:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (min-width: 1220px) {
  .u-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xl:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 319px) {
  .u-hide-at-lt-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-xs:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 539px) {
  .u-hide-at-lt-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-sm:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 767px) {
  .u-hide-at-lt-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-md:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .u-hide-at-lt-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-lg:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 1219px) {
  .u-hide-at-lt-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-xl:first-child + * {
    margin-block-start: 0;
  }
}
.u-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

@media only screen and (min-width: 320px) {
  .u-visually-hidden-at-xs {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (min-width: 540px) {
  .u-visually-hidden-at-sm {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (min-width: 768px) {
  .u-visually-hidden-at-md {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .u-visually-hidden-at-lg {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (min-width: 1220px) {
  .u-visually-hidden-at-xl {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 319px) {
  .u-visually-hidden-at-lt-xs {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 539px) {
  .u-visually-hidden-at-lt-sm {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 767px) {
  .u-visually-hidden-at-lt-md {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 1023px) {
  .u-visually-hidden-at-lt-lg {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 1219px) {
  .u-visually-hidden-at-lt-xl {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
.u-visually-hidden-focus:active, .u-visually-hidden-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
  margin: 0;
}

/**
 * Default link style
 *
 */
.u-base-link {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.u-base-link.visited, .u-base-link:visited {
  color: #a345c9;
}
.u-base-link.hover, .u-base-link:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.u-base-link.active, .u-base-link:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
.u-base-link.focus, .u-base-link:focus {
  outline: 4px solid #ffcc00;
}

/**
 * Interface links
 *
 */
.u-interface-link.active, .u-interface-link:active, .u-interface-link.hover, .u-interface-link:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

/**
 * Alternate link style
 *
 */
.u-gray-link {
  color: #666666;
}
.u-gray-link.visited, .u-gray-link:visited, .u-gray-link.hover, .u-gray-link:hover {
  color: #666666;
}

.u-deemphasised-link {
  color: #333333;
}
.u-deemphasised-link.visited, .u-deemphasised-link:visited {
  color: #333333;
}

/**
 * Unvisited
 * Override visited color
 *
 */
.u-unvisited.visited, .u-unvisited:visited {
  color: #004b83;
}
.u-unvisited.hover, .u-unvisited:hover {
  color: rgb(0, 96.6984732824, 168.9);
}

/**
 * Link Inherit
 * Make links inherit color from parent
 *
 */
.u-link-inherit {
  color: inherit;
}
.u-link-inherit.visited, .u-link-inherit:visited {
  color: inherit;
}
.u-link-inherit.hover, .u-link-inherit:hover {
  color: inherit;
}

/**
 * Link Like
 * Make non-anchor tags appear as links
 *
 */
.u-link-like {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}
.u-link-like.visited, .u-link-like:visited {
  color: #a345c9;
}
.u-link-like.hover, .u-link-like:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.u-link-like.active, .u-link-like:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
.u-link-like.focus, .u-link-like:focus {
  outline: 4px solid #ffcc00;
}

.u-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-inline > li {
  display: inline;
}

.u-button--tertiary {
  font-size: 0.875rem;
  padding: 8px 16px;
}

@media only screen and (max-width: 539px) {
  .u-button--alt-colour-on-mobile {
    background-color: #f2f2f2;
    background-image: linear-gradient(to bottom, #fff, #f2f2f2);
    border: 1px solid #ccc;
    color: #004b83;
  }
  .u-button--alt-colour-on-mobile:visited {
    color: #004b83;
  }
  .u-button--alt-colour-on-mobile:hover {
    color: #fff;
    background-color: #0061a9;
  }
}

/**
 * Pages
 */
@media only screen and (min-width: 1024px) {
  .app-login {
    display: flex;
    align-items: flex-start;
  }
}

.app-login__form {
  margin-bottom: 24px;
}
@media only screen and (min-width: 1024px) {
  .app-login__form {
    flex: 1 1 auto;
    margin-right: 32px;
  }
}

.app-login__details {
  font-size: 1rem;
  padding: 16px;
  border: 1px solid rgb(0, 96.6984732824, 168.9);
  background-color: #f7fbfe;
}
.app-login__details > *:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .app-login__details {
    flex: 0 1 auto;
    max-width: 35%;
  }
}

.app-subject-grid {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
}

.app-subject-grid > * {
  flex: 1 0 100%;
}
@media only screen and (min-width: 768px) {
  .app-subject-grid > * {
    flex: 0 0 48%;
  }
}

.publisher-homepage h1 {
  font-size: 2.875rem;
}

.cms-article {
  overflow: hidden;
}
.cms-article ul li,
.cms-article ol li {
  margin-bottom: 0.4em;
}
.cms-article ul ul,
.cms-article ul ol,
.cms-article ol ul,
.cms-article ol ol {
  margin-top: 0.4em;
}

.cms-content h2 {
  margin-top: 1.5em;
}
.cms-content h3 {
  margin-top: 2em;
}

.cms-collection__title {
  border-top: 1px solid #cccccc;
  margin-top: 24px;
  padding-top: 24px;
}

.cms-collection:first-child .cms-collection__title {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

@media only screen and (min-width: 1024px) {
  .cms-collection__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1024px) {
  .cms-collection__column {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 20px);
    margin-top: 0;
    width: 100%;
  }
}

.cms-collection--untitled .cms-collection__column-inner {
  border-top: 1px solid #cccccc;
  margin-top: 24px;
  padding-top: 24px;
}

@media only screen and (min-width: 1024px) {
  .cms-collection--untitled:first-child .cms-collection__column-inner {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .cms-collection--untitled:first-child .cms-collection__column:first-child .cms-collection__column-inner:first-child {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
}

h1 + .cms-collection--untitled .cms-collection__column-inner {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.cms-item {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  position: relative;
}

.cms-item + .cms-item {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #cccccc;
}

.cms-item + .cms-item.cms-item--indexed {
  border-top: 0;
}

.cms-item + .cms-item.cms-collection {
  padding-top: 0;
  border-top: 0;
}
.cms-item + .cms-item.cms-collection > .cms-collection__title {
  padding-top: 24px;
  border-top: 1px solid #cccccc;
}

.cms-item + .cms-item.cms-collection--split {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}

.cms-item + .cms-item.cms-editor-note {
  padding-top: 24px;
}

.main-sidebar .cms-item + .cms-item {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}

.cms-item p {
  margin-bottom: 1.5em;
}

.cms-editor-note {
  border: 1px solid #cccccc;
  padding: 24px 16px;
  color: #333333;
  /*
   * Override Cms wysiwyg styles
   * because this layout variant
   * doesn't allow it.
   */
}
.cms-editor-note p,
.cms-editor-note h2,
.cms-editor-note h3,
.cms-editor-note h4,
.cms-editor-note h5,
.cms-editor-note h6,
.cms-editor-note ul,
.cms-editor-note li,
.cms-editor-note blockquote {
  line-height: 1.4;
}
.cms-editor-note p:last-child,
.cms-editor-note h2:last-child,
.cms-editor-note h3:last-child,
.cms-editor-note h4:last-child,
.cms-editor-note h5:last-child,
.cms-editor-note h6:last-child,
.cms-editor-note ul:last-child,
.cms-editor-note li:last-child,
.cms-editor-note blockquote:last-child {
  margin-bottom: 0;
}
.cms-editor-note p {
  font-size: 1rem;
  margin-bottom: 16px;
}
.cms-editor-note p:empty {
  margin-bottom: 0;
}
.cms-editor-note h2,
.cms-editor-note h3,
.cms-editor-note h4,
.cms-editor-note h5,
.cms-editor-note h6 {
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #00285a;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 16px;
}
.cms-editor-note h2:first-child,
.cms-editor-note h3:first-child,
.cms-editor-note h4:first-child,
.cms-editor-note h5:first-child,
.cms-editor-note h6:first-child {
  margin-top: 0;
}
.cms-editor-note ul,
.cms-editor-note ol {
  font-size: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}
.cms-editor-note ul li,
.cms-editor-note ol li {
  margin-bottom: 16px;
}
.cms-editor-note img {
  display: none;
}
.cms-editor-note blockquote {
  margin-top: 0;
  margin-bottom: 16px;
  padding: 0;
  color: #333333;
  border-bottom: 0;
  font-weight: 400;
}
.cms-editor-note blockquote::before {
  display: none;
}
.cms-editor-note--small {
  padding-top: 16px;
  padding-bottom: 16px;
}
.cms-editor-note--small h2,
.cms-editor-note--small h3,
.cms-editor-note--small h4,
.cms-editor-note--small h5,
.cms-editor-note--small h6,
.cms-editor-note--small p {
  margin-bottom: 8px;
}
.cms-editor-note .float--right {
  display: block;
}
@media only screen and (min-width: 768px) {
  .cms-editor-note .float--right {
    float: none;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) {
  .cms-editor-note .float--left {
    float: none;
    margin: 0;
  }
}
.cms-editor-note .intro--paragraph {
  padding-bottom: 0;
  border-bottom: 0;
}
.cms-editor-note .divider--paragraph {
  font-size: 0;
  border-top: 0;
}

.cms-video iframe {
  max-width: 100%;
}

.cms-video__body {
  margin-top: 16px;
  line-height: 1.4;
}
.cms-video__body > p {
  margin-bottom: 0.5em;
}
.cms-video__body > p:last-child {
  margin-bottom: 0;
}

.intro--paragraph {
  padding-bottom: 16px;
  border-bottom: 1px solid #cccccc;
}

.divider--paragraph {
  border-top: 1px solid #cccccc;
  padding-top: 1.5em;
}

.float--right {
  float: right;
  margin-left: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .float--right {
    margin-left: 16px;
    margin-bottom: 16px;
  }
}

.float--left {
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .float--left {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.strike {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.app-twitter-timeline {
  max-height: 700px;
  overflow-y: auto;
  max-width: 454px;
}

body {
  font-size: 1.125rem;
}

.c-header__item .c-dropdown__button {
  margin: 0;
}

.c-header__navigation {
  display: flex;
  gap: 0.5rem;
}

.u-full-height {
  height: 100%;
}