$c-badge-font-size: $context--font-size-xs;
$c-badge-border: true;

$c-badge-themes: (
	'success': color('success'),
	'info': color('action-background-base'),
	'warning': color('warning', 'light'),
	'error': color('error', 'light'),
	'neutral': greyscale(95)
);
